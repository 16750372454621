import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import { useData } from '../../context/DataContext'
import { PageTitle } from '../../fragments/PageTitle'
import { MenuButton } from '../../fragments/Buttons/MenuButton'
import MenuGroup from '../../fragments/MenuGroup'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { v4 as uuid } from 'uuid'
import { sort } from '../../../utilities/sort'
import { useAuthentication } from '../../context/AuthenticationContext'
import { Redirect } from 'react-router-dom'

function ViewCustomerRow({ customer }) {
  const { id = '', title = '', firstName = '', lastName = '' } = customer || {}

  return (
    <MenuButton
      to={`/Customers/${id}`}
      icon={PersonIcon}
      label={[title, firstName, lastName].filter((item) => !!item).join(' ')}
      color="secondary"
    />
  )
}

export default function Customers() {
  const { customers } = useData()
  const { isManager } = useAuthentication()
  const [sortedCustomers, setSortedCustomers] = useState([])
  const [recentCustomers, setRecentCustomers] = useState([])
  const [lessRecentCustomers, setLessRecentCustomers] = useState([])
  const [remainingCustomers, setRemainingCustomers] = useState([])
  const [showRemainingCustomers, setShowRemainingCustomers] = useState(false)
  const [showLessRecentCustomers, setShowLessRecentCustomers] = useState(false)
  const dateLessOneMonth = new Date()
  dateLessOneMonth.setDate(dateLessOneMonth.getDate() - 31)
  const dateLessSixMonths = new Date()
  dateLessSixMonths.setDate(dateLessSixMonths.getDate() - 183)

  useEffect(() => {
    if (customers?.length) {
      setSortedCustomers(
        customers
          .map(
            ({
              address,
              updatedAt,
              invoices,
              visitsYetToBeInvoiced,
              ...customer
            }) => {
              const dates = [
                updatedAt,
                address.updatedAt,
                ...invoices.map(({ updatedAt }) => updatedAt),
                ...visitsYetToBeInvoiced.map(({ updatedAt }) => updatedAt),
              ].map((date) => new Date(date))
              const updated = dates.sort((dateB, dateA) =>
                sort(dateA, dateB)
              )[0]
              return {
                ...customer,
                updated,
              }
            }
          )
          .sort(
            (
              { firstName: fa, lastName: la },
              { firstName: fb, lastName: lb }
            ) => sort(`${fa}--${la}`, `${fb}--${lb}`)
          )
      )
    }
  }, [customers])

  useEffect(() => {
    if (sortedCustomers?.length) {
      setRecentCustomers(
        sortedCustomers.filter(({ updated }) => updated >= dateLessOneMonth)
      )
      setLessRecentCustomers(
        sortedCustomers.filter(
          ({ updated }) =>
            updated >= dateLessSixMonths && updated < dateLessOneMonth
        )
      )
      setRemainingCustomers(
        sortedCustomers.filter(({ updated }) => updated < dateLessSixMonths)
      )
    }
  }, [sortedCustomers])

  const handleLessRecentCustomers = () => {
    setShowLessRecentCustomers(true)
  }

  const handleRemainingCustomers = () => {
    setShowRemainingCustomers(true)
  }

  return (
    <>
      {!isManager ? (
        <Redirect to={{ pathname: '/Home' }} />
      ) : (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={PeopleIcon} title="Customers" />

          {/*  <MenuGroup label={'Assignments:'}>*/}
          {/*  </MenuGroup>*/}

          {!!sortedCustomers?.length && (
            <>
              {remainingCustomers?.length && (
                <MenuGroup label={'Recent customers:'}>
                  {recentCustomers.map((customer) => (
                    <ViewCustomerRow
                      key={`customer-${customer.id}`}
                      customer={customer}
                    />
                  ))}{' '}
                </MenuGroup>
              )}

              {!!showLessRecentCustomers && lessRecentCustomers?.length && (
                <MenuGroup label={'Less recent customers:'}>
                  {lessRecentCustomers.map((customer) => (
                    <ViewCustomerRow
                      key={`customer-${customer.id}`}
                      customer={customer}
                    />
                  ))}{' '}
                </MenuGroup>
              )}

              {!!showRemainingCustomers && remainingCustomers?.length && (
                <MenuGroup label={'Remaining customers:'}>
                  {remainingCustomers.map((customer) => (
                    <ViewCustomerRow
                      key={`customer-${customer.id}`}
                      customer={customer}
                    />
                  ))}{' '}
                </MenuGroup>
              )}
            </>
          )}

          <MenuGroup label={' '}>
            <>
              {!showLessRecentCustomers && lessRecentCustomers?.length > 0 && (
                <MenuButton
                  label="Less recent Customers"
                  onClick={handleLessRecentCustomers}
                />
              )}
              {(showLessRecentCustomers || lessRecentCustomers?.length === 0) &&
                !showRemainingCustomers &&
                remainingCustomers?.length > 0 && (
                  <MenuButton
                    label="Remaining Customers"
                    onClick={handleRemainingCustomers}
                  />
                )}
              <MenuButton
                to={`/Customers/${uuid()}/Edit`}
                icon={AddIcon}
                label="New Customer"
              />
            </>

            <NavButtons backTo="/Home" />
          </MenuGroup>
        </Grid>
      )}
    </>
  )
}
