import { Card, CardContent, Grid, IconButton } from '@mui/material'
import clsx from 'clsx'
import PhoneIcon from '@mui/icons-material/Phone'
import SmsIcon from '@mui/icons-material/Sms'
import EmailIcon from '@mui/icons-material/Email'
import DirectionsIcon from '@mui/icons-material/Directions'
import { Link } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { usePerson } from '../context/PersonContext'
import { StyledModal } from './StyledModal'
import { FullAddress } from './FullAddress'
import { useAuthentication } from '../context/AuthenticationContext'
import { directionsAddress } from '../../utilities/formatAddress'

const PREFIX = 'LdgApp-Person-Card'

const classes = {
  card: `${PREFIX}-card`,
  billPayer: `${PREFIX}-bill-payer`,
  buttons: `${PREFIX}-buttons`,
}

const Root = styled('div')(({ theme }) => ({
  [`.${classes.card}`]: {
    backgroundColor: '#fccc7c',
    marginLeft: theme.spacing(2),
    position: 'relative',
  },
  [`.${classes.billPayer}`]: {
    backgroundColor: '#3bb027',
    marginLeft: theme.spacing(2),
  },
  [`.${classes.buttons}`]: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'space-around',
  },
}))

export function PersonCard({ isBillPayer, editLink, onDelete }) {
  const { fullName, address, contact, distance, fullAddress } = usePerson()
  const { isManager } = useAuthentication()
  const { phone, email } = contact
  const { mileage = null } = distance || {}
  const [deleteModelOpen, setDeleteModelOpen] = useState(false)

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`
  }

  const handlePhoneClick = () => {
    window.location.href = `tel:${phone}`
  }

  const handleTextClick = () => {
    window.location.href = `sms:${phone}`
  }

  const handleDirectionsClick = () => {
    window.location.href = `https://www.google.com/maps/search/${encodeURIComponent(
      directionsAddress(address)
    )}`
  }

  const handleDeleteRequest = () => {
    setDeleteModelOpen(true)
  }

  const handleDelete = () => {
    onDelete()
  }

  return (
    <Root>
      <Grid item xs={12}>
        <Card
          className={
            isBillPayer ? clsx(classes.card, classes.billPayer) : classes.card
          }
        >
          <CardContent>
            <strong>{fullName}</strong>
            {!!address && <FullAddress address={address} />}
            {isManager && mileage !== null && (
              <p>
                {!!mileage
                  ? `${mileage} miles from home`
                  : 'mileage to be entered'}
              </p>
            )}
          </CardContent>
          {isManager && (
            <div className={classes.buttons}>
              {!!phone && (
                <>
                  <IconButton
                    edge="end"
                    variant="contained"
                    color={'primary'}
                    onClick={handlePhoneClick}
                    tabIndex={0}
                  >
                    <PhoneIcon />
                  </IconButton>
                  {phone.startsWith('07') && (
                    <IconButton
                      edge="end"
                      variant="contained"
                      color={'primary'}
                      onClick={handleTextClick}
                      tabIndex={0}
                    >
                      <SmsIcon />
                    </IconButton>
                  )}
                </>
              )}
              {!!email && (
                <IconButton
                  edge="end"
                  variant="contained"
                  color={'primary'}
                  onClick={handleEmailClick}
                  tabIndex={0}
                >
                  <EmailIcon />
                </IconButton>
              )}
              {!!fullAddress && (
                <IconButton
                  edge="end"
                  variant="contained"
                  color={'primary'}
                  onClick={handleDirectionsClick}
                  tabIndex={0}
                >
                  <DirectionsIcon />
                </IconButton>
              )}
              {!!editLink && (
                <>
                  {!!isBillPayer && (
                    <IconButton
                      edge="end"
                      variant="contained"
                      color={'primary'}
                      onClick={handleDeleteRequest}
                      tabIndex={0}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    variant="contained"
                    color={'primary'}
                    to={editLink}
                    component={Link}
                    tabIndex={0}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </div>
          )}
        </Card>
      </Grid>
      <StyledModal
        open={deleteModelOpen}
        setOpen={setDeleteModelOpen}
        title="Are you sure you want to delete the customer's Bill Payer?"
        onClickYes={handleDelete}
      />
    </Root>
  )
}
