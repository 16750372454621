import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Grid } from '@mui/material'
import React from 'react'
import { useAssignment } from '../../../context/AssignmentContext'
import { PageTitle } from '../../../fragments/PageTitle'
import { NavButtons } from '../../../fragments/Buttons/NavButtons'
import { useRouteMatch } from 'react-router-dom'
import { AssignmentCard } from '../../../fragments/AssignmentCard'

export default function AssignmentCheck({ customerId, assignmentId }) {
  const { assignmentDateTime, isNewAssignment } = useAssignment()
  const { assignmentDate } = assignmentDateTime || {}

  let match = useRouteMatch()

  const path = match.path
    .replace(':customerId', customerId)
    .replace(':assignmentId', assignmentId)

  const backTo = path.substring(
    0,
    path.lastIndexOf('/Assignments/') + (isNewAssignment ? 0 : 12)
  )

  const continueTo = path.substring(0, path.lastIndexOf('/Edit/') + 6) + 'Save'

  return (
    <>
      {assignmentDate && (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={AccessTimeIcon} title="Assignment save" />

          <Grid item xs={12}>
            <p>Please check this is correct before you press save:</p>
          </Grid>

          <AssignmentCard
            editText="Edit if required"
            editLink={
              path.substring(0, path.lastIndexOf('/Edit/') + 6) +
              'AssignmentDateTime'
            }
          />
          <NavButtons
            backTo={backTo}
            backLabel="Cancel"
            continueTo={continueTo}
            continueLabel="Save"
          />
        </Grid>
      )}
    </>
  )
}
