import { PageTitle } from '../../../fragments/PageTitle'
import WorkerIcon from '@mui/icons-material/AccountBox'
import React from 'react'
import { Grid } from '@mui/material'
import FormField from '../../../fragments/FormField'
import { NavButtons } from '../../../fragments/Buttons/NavButtons'
import { useRouteMatch } from 'react-router-dom'
import MenuGroup from '../../../fragments/MenuGroup'
import { useAuthentication } from '../../../context/AuthenticationContext'

export default function WorkerEditView({
  workerId,
  subHeading,
  fields,
  data,
  setData,
  next,
  prev,
}) {
  const { user } = useAuthentication()
  let match = useRouteMatch()
  const path = match.path.replace(':workerId', workerId)

  const backTo = prev
    ? path.substring(0, path.lastIndexOf('/Edit/') + 6) + prev
    : `/Workers/${workerId}`

  const continueTo = next
    ? path.substring(0, path.lastIndexOf('/Edit/') + 6) + next
    : 'Save'

  const handleChange = ({ target }) => {
    setData((data) => ({ ...data, [target.name]: target.value }))
  }

  return (
    <Grid container direction="column" spacing={2} alignContent="stretch">
      <PageTitle
        icon={WorkerIcon}
        title={user?.id === workerId ? 'Profile' : 'Worker'}
      />

      {!!data && (
        <MenuGroup
          label={`${subHeading || path.substring(path.lastIndexOf('/') + 1)}:`}
        >
          {fields.map(({ field, label, type, options }) => {
            return (
              <Grid key={field} item xs={12} sm={6} md={4}>
                <FormField
                  name={field}
                  label={label}
                  value={data[field] ?? ''}
                  onChange={handleChange}
                  type={type}
                  options={options}
                />
              </Grid>
            )
          })}
        </MenuGroup>
      )}
      <NavButtons
        backTo={backTo}
        backLabel={prev ? 'Back' : 'Cancel'}
        continueTo={continueTo}
        continueLabel={next ? 'Continue' : 'Save'}
      />
    </Grid>
  )
}
