import { FullAddress } from './FullAddress'
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useAuthentication } from '../context/AuthenticationContext'
import { Link } from 'react-router-dom'
import formatName from '../../utilities/formatName'
import { useData } from '../context/DataContext'

const formatDate = (date) => format(date, 'EEEE, dd MMMM yyyy')
const formatTime = (time) => format(time, 'h:mm a')

export function VisitContent({
  visitDate,
  startTime,
  finishTime,
  property,
  customerId,
  showAddress,
}) {
  const { isManager } = useAuthentication()
  const { getCustomerById } = useData()
  const [fullName, setFullName] = useState('')
  const [address, setAddress] = useState('')

  useEffect(() => {
    if (customerId && getCustomerById) {
      const customer = getCustomerById(customerId)
      setAddress(
        property?.address ||
          customer?.properties?.find(
            (customerProperty) => customerProperty.id === property?.id
          )?.address
      )
      setFullName(formatName(customer))
    }
  }, [customerId, getCustomerById])

  return (
    <>
      <strong>
        {!!visitDate && (
          <>
            {formatDate(visitDate)}
            <br />
            {formatTime(startTime)}
            {formatTime(startTime) !== formatTime(finishTime) && (
              <> to {formatTime(finishTime)}</>
            )}
          </>
        )}
      </strong>
      {showAddress && (
        <>
          {!!fullName && (
            <>
              <br />
              <br />
              {!!isManager ? (
                <Link
                  to={`/Customers/${customerId}`}
                  style={{ textDecoration: 'underline', color: 'primary' }}
                >
                  {' '}
                  {fullName}{' '}
                </Link>
              ) : (
                <>{fullName}</>
              )}
            </>
          )}
          {!!address && (
            <>
              {!fullName && (
                <>
                  <br />
                  <br />
                </>
              )}
              <FullAddress address={address} />
            </>
          )}
        </>
      )}
    </>
  )
}
