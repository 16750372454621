import { styled } from '@mui/material/styles'

export default function getCardStyle(prefix, backgroundColor = '#fde9c7') {
  const classes = {
    card: `${prefix}-card`,
    buttons: `${prefix}-buttons`,
  }

  const Root = styled('div')(({ theme }) => ({
    [`.${classes.card}`]: {
      backgroundColor,
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      position: 'relative',
    },
    [`.${classes.buttons}`]: {
      borderTopLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.contrastText,
      display: 'flex',
      justifyContent: 'space-around',
    },
  }))

  return { Root, classes }
}
