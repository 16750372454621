import React, { useEffect, useState } from 'react'
import { useData } from '../context/DataContext'
import { styled } from '@mui/material/styles'
import { VisitCard } from './VisitCard'
import { VisitProvider } from '../context/VisitContext'
import { useAuthentication } from '../context/AuthenticationContext'

const PREFIX = 'LdgApp-Visit-Details'

const classes = {
  label: `${PREFIX}-label`,
}

const Root = styled('div')(({ theme }) => ({
  [`.${classes.label}`]: {
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(1.5),
  },
}))

export function VisitDetails({
  customerId,
  visitId,
  hideVisitor,
  hideEdit,
  canDelete,
  onDelete,
}) {
  const data = useData()
  const { isManager } = useAuthentication()
  const [visit, setVisit] = useState()

  useEffect(() => {
    setVisit(data?.getVisitById(visitId))
  }, [data?.getVisitById])

  return (
    <Root>
      {!!visit && (
        <VisitProvider visitId={visitId}>
          <VisitCard
            customerId={customerId}
            visit={visit}
            editLink={
              !isManager && visit?.tasks?.length
                ? ''
                : `/Customers/${customerId}/Visits/${visitId}/Edit/Finish`
            }
            editText={visit?.tasks?.length ? '' : 'Finish Visit'}
            hideVisitor={hideVisitor}
            hideEdit={hideEdit}
            canDelete={canDelete}
            onDelete={onDelete}
          />
        </VisitProvider>
      )}
    </Root>
  )
}
