import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useAuthentication } from '../context/AuthenticationContext'
import { VisitTaskRow } from './VisitTaskRow'
import { ccyFormat } from '../../utilities/ccyFormat'

function getTotal(tasks, isManager) {
  return tasks.reduce(
    (total, { quantity, price, cost }) =>
      quantity ? total + quantity * (isManager ? price : cost) : total,
    0
  )
}

function VistTaskCostCell({ totalIsVisible = false }) {
  return totalIsVisible ? (
    <>
      <TableCell style={{ padding: 0, fontWeight: 'bold' }} align="right">
        Cost
      </TableCell>
    </>
  ) : null
}

function VisitTaskHeadings({ tasks, totalIsVisible = false }) {
  return (
    <TableRow>
      <TableCell style={{ padding: 0, fontWeight: 'bold' }} align="left">
        Task
      </TableCell>
      <TableCell style={{ padding: 0, fontWeight: 'bold' }} align="right">
        Hours
      </TableCell>
      <VistTaskCostCell tasks={tasks} totalIsVisible={totalIsVisible} />
    </TableRow>
  )
}

function VisitTaskTotalRow({ tasks, totalIsVisible = false }) {
  const { isManager } = useAuthentication()
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setTotal(getTotal(tasks, isManager))
  }, [tasks])

  return totalIsVisible ? (
    <>
      <TableRow>
        <TableCell
          style={{ padding: 0, fontWeight: 'bold' }}
          colSpan={2}
          align="right"
        >
          <>Total</>
        </TableCell>

        <TableCell style={{ padding: 0 }} align="right">
          {ccyFormat(total)}
        </TableCell>
      </TableRow>
    </>
  ) : null
}

export function VisitTaskTable({ tasks, placeholder = false }) {
  const { isManager } = useAuthentication()
  const [totalIsVisible, setTotalIsVisible] = useState(false)

  useEffect(() => {
    setTotalIsVisible(isManager && !placeholder)
    // setTotalIsVisible(!placeholder)
  }, [placeholder, isManager])

  return (
    <>
      <br />
      <Table>
        <TableHead>
          <VisitTaskHeadings tasks={tasks} totalIsVisible={totalIsVisible} />
        </TableHead>
        <TableBody>
          {tasks
            .filter(({ taskType }) => !!taskType)
            .map((task) => (
              <VisitTaskRow
                key={`task-${task.id}`}
                task={task}
                totalIsVisible={totalIsVisible}
              />
            ))}
          {tasks
            .filter(({ taskType }) => !taskType)
            .map((task) => (
              <VisitTaskRow
                key={`task-${task.id}`}
                task={task}
                totalIsVisible={totalIsVisible}
              />
            ))}
          <VisitTaskTotalRow tasks={tasks} totalIsVisible={totalIsVisible} />
        </TableBody>
      </Table>
    </>
  )
}
