import React, { useEffect } from 'react'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import MenuIcon from '@mui/icons-material/Menu'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import SyncIcon from '@mui/icons-material/Sync'
import { useAuthentication } from '../context/AuthenticationContext'
import { useGlobal } from '../context/GlobalContext'
import { styled } from '@mui/material/styles'
import { StyledModal } from './StyledModal'

const PREFIX = 'LdgApp-Header'

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menu-button`,
  title: `${PREFIX}-title`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    marginBottom: '1em',
  },
  [`.${classes.menuButton}`]: {
    marginLeft: theme.spacing(2),
  },
  [`.${classes.title}`]: {
    flexGrow: 1,
  },
}))

export default function Header({ title }) {
  const { menuVisible, setMenuVisible, syncing, error, online } = useGlobal()
  const { isAuthenticated } = useAuthentication()
  const [errorModelOpen, setErrorModalOpen] = React.useState(false)
  const [timerId, setTimerId] = React.useState(null)

  const onMenuClick = () => {
    setMenuVisible(!menuVisible)
  }

  const handleError = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (error) {
      setErrorModalOpen(true)
    }
  }, [error])

  useEffect(() => {
    if (online) {
      clearTimeout(timerId)
    }
  }, [online])

  useEffect(() => {
    if (syncing) {
      setTimerId(
        setTimeout(() => {
          if (syncing) {
            setErrorModalOpen(true)
          }
        }, 30000)
      )
    } else {
      clearTimeout(timerId)
    }
  }, [syncing])

  return (
    <Root className={classes.root}>
      <AppBar
        position="static"
        sx={error ? { bgcolor: 'red' } : syncing ? { bgcolor: 'orange' } : {}}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {isAuthenticated() && (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={onMenuClick}
              style={{ float: 'right' }}
            >
              {error ? (
                <ErrorIcon />
              ) : syncing ? (
                <SyncIcon />
              ) : online ? (
                <MenuIcon />
              ) : (
                <CloudOffIcon />
              )}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <StyledModal
        open={errorModelOpen}
        setOpen={setErrorModalOpen}
        title="An error has occurred!"
        onClickContinue={handleError}
      >
        <p>Press continue to refresh and try again.</p>
      </StyledModal>
    </Root>
  )
}
