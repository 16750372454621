import React from 'react'
import { useWorker } from '../../../context/WorkerContext'
import { nameFields } from '../../../../constants/fields'
import WorkerEditView from './WorkerEditView'

export default function WorkerNameEdit(params) {
  const { name, setName } = useWorker()
  return (
    <WorkerEditView
      {...params}
      next="Contact"
      fields={nameFields}
      data={name}
      setData={setName}
    />
  )
}
