import { PageTitle } from '../../fragments/PageTitle'
import ReportIcon from '@mui/icons-material/Summarize'
import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { useParams } from 'react-router-dom'
import { getMonthName } from '../../../utilities/getMonthName'
import SetInnerHTML from '../../fragments/SetInnerHTML'
import { StyledModal } from '../../fragments/StyledModal'
import { MenuButton } from '../../fragments/Buttons/MenuButton'
import EmailIcon from '@mui/icons-material/Email'
import Loader from '../../fragments/Loader'
import { useAuthentication } from '../../context/AuthenticationContext'

const getLastDayOfMonth = (year, month) => {
  const date = new Date(Date.UTC(parseFloat(year), parseFloat(month) + 1, 0))
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export default function ViewReport() {
  const [sending, setSending] = useState(false)
  const [sendModalOpen, setSendModalOpen] = useState(false)
  const { token } = useAuthentication()
  const { yearStart, month } = useParams()
  const monthNo = parseInt(month)
  const yearEnd = monthNo > 12 ? month : ''

  const title = yearEnd
    ? `${yearStart} - ${yearEnd}`
    : `${getMonthName(monthNo)} ${yearStart}`

  const dateCreatedStart = yearEnd
    ? `${yearStart}-04-01`
    : `${yearStart}-${monthNo + 1}-01`

  const dateCreatedEnd = yearEnd
    ? `${yearEnd}-03-31`
    : getLastDayOfMonth(yearStart, month)

  const query = `?dateCreatedStart=${dateCreatedStart}&dateCreatedEnd=${dateCreatedEnd}`

  let url = `${process.env.REACT_APP_API}/reports/invoices${query}`
  let sendUrl = `${process.env.REACT_APP_API}/reports/invoices/send${query}`

  if (yearEnd) {
    url += '&summary=true'
  }

  const handleSendRequest = () => {
    setSendModalOpen(true)
  }

  const handleSend = () => {
    setSending(true)
    fetch(sendUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.warn(
            `Looks like there was a problem. Status Code: ${response.status}`
          )
        } else {
          setSending(false)
        }
      })
      .catch((err) => {
        setSending(false)
        console.warn('Something went wrong.', err)
      })
  }

  return (
    <>
      <Grid container direction="column" spacing={2} alignContent="stretch">
        <PageTitle icon={ReportIcon} title={title} />
        <SetInnerHTML url={url} />
        {!sending ? (
          <MenuButton
            onClick={handleSendRequest}
            icon={EmailIcon}
            label="Send Invoice Report"
          />
        ) : (
          <Loader />
        )}
        <NavButtons
          backTo={`/Reports/${
            monthNo <= 2 ? parseInt(yearStart) - 1 : yearStart
          }`}
        />
      </Grid>
      <StyledModal
        open={sendModalOpen}
        setOpen={setSendModalOpen}
        title="Are you sure you want to send the invoice report?"
        onClickYes={handleSend}
      />
    </>
  )
}
