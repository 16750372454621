import React, { useContext, useEffect, useState } from 'react'
import formatName from '../../utilities/formatName'
import { useData } from './DataContext'

export const WorkerContext = React.createContext(undefined)

export const useWorker = () => useContext(WorkerContext)

export const WorkerProvider = ({ children, workerId }) => {
  const [name, setName] = useState({})
  const [contact, setContact] = useState({})
  const [address, setAddress] = useState({})
  const [taskTypeAndRate, setTaskTypeAndRate] = useState({})
  const [fullName, setFullName] = useState('')
  const { getUserById, saveUser, taskTypes } = useData()

  useEffect(() => {
    let worker
    if (workerId) {
      worker = getUserById(workerId)
    }
    if (worker) {
      const { rate, defaultTaskType, address, phone, email, id, ...name } =
        worker
      setName({ ...name })
      setContact({ phone, email })
      setAddress({ ...address })
      setTaskTypeAndRate({
        rate: `${rate}`,
        defaultTaskType: defaultTaskType?.id,
      })
    }
  }, [workerId])

  useEffect(() => {
    if (name) {
      setFullName(formatName(name))
    }
  }, [name])

  const save = () => {
    let worker = getUserById(workerId) || {
      id: workerId,
    }
    const defaultTaskType = taskTypes.find(
      (taskType) => taskType.id === taskTypeAndRate.defaultTaskType
    )
    const { rate } = taskTypeAndRate
    const changedWorker = {
      ...worker,
      ...name,
      ...contact,
      address,
      rate,
      defaultTaskType,
    }
    saveUser(changedWorker)
  }

  return (
    <WorkerContext.Provider
      value={{
        name,
        setName,
        contact,
        setContact,
        address,
        setAddress,
        taskTypeAndRate,
        setTaskTypeAndRate,
        fullName,
        save,
      }}
    >
      {children}
    </WorkerContext.Provider>
  )
}
