import { Button, Card, CardContent, Grid, IconButton } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useEffect, useState } from 'react'
import { useVisit } from '../context/VisitContext'
import formatName from '../../utilities/formatName'
import { useData } from '../context/DataContext'
import { VisitTaskTable } from './VisitTaskTable'
import { useAuthentication } from '../context/AuthenticationContext'
import { VisitContent } from './VisitContent'
import getCardStyle from '../../styles/card'
import { StyledModal } from './StyledModal'

const { classes, Root } = getCardStyle('LdgApp-Visit-Details')

const formatHours = (duration) => duration / (60 * 60 * 1000)

export function VisitCard({
  editLink,
  editText,
  customerId,
  mode,
  hideVisitor,
  hideEdit,
  canDelete,
  onDelete = (_) => {},
}) {
  const { customerId: visitCustomerId } = useParams()
  const { getCustomerById } = useData()
  const { property, tasks, visitDateTime, user, visitId } = useVisit()
  const { isManager, user: currentUser } = useAuthentication()
  const { visitDate, startTime, finishTime } = visitDateTime || {}
  const [displayCard, setDisplayCard] = useState()
  const [visitor, setVisitor] = useState('')
  const [customer, setCustomer] = useState()
  const [showAddress, setShowAddress] = useState(false)
  const [deleteModelOpen, setDeleteModelOpen] = useState(false)

  useEffect(() => {
    if (!!startTime && startTime.toDateString() !== 'Invalid Date') {
      setDisplayCard(true)
    }
  }, [startTime])

  useEffect(() => {
    if ((visitCustomerId || customerId) && getCustomerById) {
      const customer = getCustomerById(customerId || visitCustomerId)
      setCustomer(customer)
    }
  }, [customerId, visitCustomerId, getCustomerById])

  useEffect(() => {
    if (visitCustomerId && visitCustomerId === customerId) {
      setShowAddress(false)
    } else {
      setShowAddress(true)
    }
  }, [customerId, visitCustomerId])

  useEffect(() => {
    setVisitor(formatName(user))
  }, [user])

  const handleDeleteRequest = () => {
    setDeleteModelOpen(true)
  }

  const handleDelete = () => {
    onDelete(visitId)
  }

  return (
    <Root>
      {!!displayCard && (
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <VisitContent
                finishTime={finishTime}
                startTime={startTime}
                property={
                  customer?.property?.find(({ id }) => id === property?.id) ||
                  property
                }
                visitDate={visitDate}
                customerId={customerId || visitCustomerId}
                showAddress={showAddress}
              />
              <br />
              {mode !== 'start' && (
                <>
                  {!!tasks?.length ? (
                    <>
                      <VisitTaskTable
                        tasks={[...tasks, { taskName: 'Breaks', quantity: 0 }]}
                      />
                    </>
                  ) : (
                    <>
                      <br />
                      <VisitTaskTable
                        tasks={[
                          {
                            taskType: user?.defaultTaskType.taskTypeName,
                            taskName: user?.defaultTaskType.taskTypeName,
                            quantity: formatHours(finishTime - startTime),
                          },
                        ]}
                        placeholder
                      />
                    </>
                  )}
                </>
              )}
              {!hideVisitor && !!visitor && isManager && (
                <>
                  <br />
                  <br />
                  <strong>Visitor: {visitor}</strong>
                </>
              )}
              {!!canDelete && (
                <>
                  <br />
                  <IconButton
                    edge="end"
                    variant="contained"
                    color={'primary'}
                    onClick={handleDeleteRequest}
                    tabIndex={0}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </CardContent>
            <div className={classes.buttons}>
              {!hideEdit && !!editLink && (
                <>
                  {!!editText ? (
                    <Button
                      startIcon={<EditIcon />}
                      variant="contained"
                      color={
                        user?.email !== currentUser?.email
                          ? 'primary'
                          : 'secondary'
                      }
                      component={Link}
                      tabIndex={0}
                      to={editLink}
                      fullWidth={true}
                      size="large"
                    >
                      {editText}
                    </Button>
                  ) : (
                    <IconButton
                      edge="end"
                      variant="contained"
                      color={'primary'}
                      to={editLink}
                      component={Link}
                      tabIndex={0}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </>
              )}
            </div>
          </Card>
          <StyledModal
            open={deleteModelOpen}
            setOpen={setDeleteModelOpen}
            title="Are you sure you want to delete the visit?"
            onClickYes={handleDelete}
          />
        </Grid>
      )}
    </Root>
  )
}
