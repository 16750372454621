import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { AssignmentProvider } from '../../../context/AssignmentContext'
import { useAuthentication } from '../../../context/AuthenticationContext'
import NotFoundComponent from '../../NotFoundComponent'
import AssignmentCreate from './AssignmentCreate'
import SelectAssignmentProperty from './SelectAssignmentProperty'
import AssignmentDateTimeEdit from './AssignmentDateTimeEdit'
import AssignmentCheck from './AssignmentCheck'
import AssignmentSave from './AssignmentSave'

export default function AssignmentEdit() {
  const { isManager } = useAuthentication()
  let match = useRouteMatch()
  const params = useParams()
  return (
    <>
      {!isManager ? (
        <Redirect to={{ pathname: '/Home' }} />
      ) : (
        <AssignmentProvider {...params}>
          <Switch>
            <Route path={`${match.path}/Property/Select`} exact>
              <SelectAssignmentProperty {...params} />
            </Route>
            <Route path={`${match.path}/Create`} exact>
              <AssignmentCreate {...params} />
            </Route>
            <Route path={`${match.path}/AssignmentDateTime`} exact>
              <AssignmentDateTimeEdit {...params} />
            </Route>
            <Route path={`${match.path}/Check`} exact>
              <AssignmentCheck {...params} />
            </Route>
            <Route path={`${match.path}/Save`} exact>
              <AssignmentSave {...params} />
            </Route>
            <Redirect
              from={match.path}
              to={`${match.url}/Property/Select`}
              exact
            />
            <Route component={NotFoundComponent} />
          </Switch>
        </AssignmentProvider>
      )}
    </>
  )
}
