import Loader from '../../../fragments/Loader'
import { useEffect, useState } from 'react'
import { useAssignment } from '../../../context/AssignmentContext'
import { Redirect, useRouteMatch } from 'react-router-dom'

export default function AssignmentSave({ customerId, assignmentId }) {
  const { save } = useAssignment()
  const [saved, setSaved] = useState(false)

  let match = useRouteMatch()

  const path = match.path
    .replace(':customerId', customerId)
    .replace(':assignmentId', assignmentId)

  const pathToRedirect = path.substring(0, path.indexOf('/Assignments/') + 12)

  useEffect(() => {
    if (save) {
      save()
      setSaved(true)
    }
  }, [save])

  return (
    <>
      {saved ? <Redirect from={path} to={pathToRedirect} exact /> : <Loader />}
    </>
  )
}
