import React from 'react'
import { useWorker } from '../../../context/WorkerContext'
import { taskTypeRateFields } from '../../../../constants/fields'
import WorkerEditView from './WorkerEditView'
import { useData } from '../../../context/DataContext'

export default function WorkerTaskTypeAndRateEdit(params) {
  const { taskTypeAndRate, setTaskTypeAndRate } = useWorker()
  const [taskType, rate] = taskTypeRateFields
  const { taskTypes } = useData()
  taskType.options = taskTypes.map(({ id, taskTypeName }) => ({
    value: id,
    label: taskTypeName,
  }))
  return (
    <WorkerEditView
      {...params}
      subHeading="Default Task and Rate"
      prev="Address"
      fields={[taskType, rate]}
      data={taskTypeAndRate}
      setData={setTaskTypeAndRate}
    />
  )
}
