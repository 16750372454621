import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import VisitSave from './VisitSave'
import { VisitProvider } from '../../../context/VisitContext'
import SelectVisitProperty from './SelectVisitProperty'
import VisitDateTimeEdit from './VisitDateTimeEdit'
import NotFoundComponent from '../../NotFoundComponent'
import VisitStart from './VisitStart'
import VisitFinish from './VisitFinish'
import VisitItemsEdit from './VisitItemsEdit'

export default function VisitEdit() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const visitType = searchParams.get('visitType')
  let match = useRouteMatch()
  const params = { ...useParams(), visitType }
  return (
    <VisitProvider {...params}>
      <Switch>
        <Route path={`${match.path}/Start`} exact>
          <VisitStart {...params} />
        </Route>
        <Route path={`${match.path}/Property/Select`} exact>
          <SelectVisitProperty {...params} />
        </Route>
        <Route path={`${match.path}/VisitDateTime`} exact>
          <VisitDateTimeEdit {...params} />
        </Route>
        <Route path={`${match.path}/VisitItems`} exact>
          <VisitItemsEdit {...params} />
        </Route>
        <Route path={`${match.path}/Finish`} exact>
          <VisitFinish {...params} />
        </Route>
        <Route path={`${match.path}/Save`} exact>
          <VisitSave {...params} />
        </Route>
        <Redirect from={match.path} to={`${match.url}/Property/Select`} exact />
        <Route component={NotFoundComponent} />
      </Switch>
    </VisitProvider>
  )
}
