import { VisitTaskTable } from './VisitTaskTable'
import FormField from './FormField'
import { format } from 'date-fns'
import { useState } from 'react'

export function InvoiceVisit({ visit, onChange, displayCheckbox }) {
  const [includeVisit, setIncludeVisit] = useState(true)

  const handleChange = ({ target }) => {
    setIncludeVisit(target.value)
    onChange({ visitId: visit.id, includeVisit: target.value })
  }

  return (
    <div>
      <br />
      {!!displayCheckbox ? (
        <FormField
          name="includeVisit"
          label={format(new Date(visit.visitDate), 'dd MMM yyyy')}
          value={includeVisit}
          onChange={handleChange}
          type="checkbox"
        />
      ) : (
        <strong>{format(new Date(visit.visitDate), 'dd MMM yyyy')}</strong>
      )}
      {!!visit.tasks?.length && <VisitTaskTable tasks={visit.tasks} />}
    </div>
  )
}
