import React from 'react'
import { useWorker } from '../../../context/WorkerContext'
import { addressFields } from '../../../../constants/fields'
import WorkerEditView from './WorkerEditView'
import { useAuthentication } from '../../../context/AuthenticationContext'
import { useParams } from 'react-router-dom'

export default function WorkerAddressEdit(params) {
  const { workerId } = useParams()
  const { address, setAddress } = useWorker()
  const { user, isManager } = useAuthentication()
  let next = 'TaskTypeAndRate'
  if (!isManager || user?.id === workerId) {
    next = null
  }
  return (
    <WorkerEditView
      {...params}
      prev="Contact"
      next={next}
      fields={addressFields}
      data={address}
      setData={setAddress}
    />
  )
}
