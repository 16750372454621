import React from 'react'
import VisitEditView from './VisitEditView'
import VisitItems from '../../../fragments/VisitItems'

export default function VisitItemsEdit(params) {
  return (
    <VisitEditView {...params} done="Finish" fields={[]}>
      <VisitItems />
    </VisitEditView>
  )
}
