import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { PageTitle } from '../../fragments/PageTitle'
import { Redirect, useParams } from 'react-router-dom'
import { useData } from '../../context/DataContext'
import { CustomerDetails } from '../../fragments/CustomerDetails'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { sort } from '../../../utilities/sort'
import InvoiceGroup from '../../fragments/InvoiceGroup'
import { useAuthentication } from '../../context/AuthenticationContext'
import formatCurrency from '../../../utilities/formatCurrency'

const getMostRecentlyPaidInvoices = (invoices, max) => {
  return (
    invoices
      .filter(
        // Invoices that have been paid
        ({ datePaid }) => datePaid
      )
      // Only the latest 10
      .sort(({ datePaid: a }, { datePaid: b }) => sort(a, b))
      .slice(invoices.length > max ? invoices.length - max : 0)
  )
}

const getUnpaidInvoices = (invoices) =>
  invoices.filter(({ datePaid, dateSent }) => !datePaid && dateSent)

const getUnsentInvoices = (invoices) =>
  invoices.filter(({ datePaid, dateSent }) => !datePaid && !dateSent)

const getTotal = (invoices) => {
  const invoicesTotal = invoices.reduce((total, { visits }) => {
    const visitsTotal = visits.reduce((total, { tasks }) => {
      const tasksTotal = tasks.reduce((total, { price, quantity }) => {
        return total + price * 100 * quantity
      }, 0)
      return total + tasksTotal
    }, 0)
    return total + visitsTotal
  }, 0)
  return formatCurrency(invoicesTotal / 100)
}

export default function Invoices() {
  const { isManager } = useAuthentication()
  const { getInvoices } = useData()
  const { customerId } = useParams()
  const [invoices, setInvoices] = useState([])
  const [prefix, setPrefix] = useState()

  useEffect(() => {
    if (customerId) {
      setPrefix(`/Customers/${customerId}`)
    }
  }, [customerId])

  useEffect(() => {
    setInvoices(getInvoices({ customerId }))
  }, [getInvoices, customerId])

  return (
    <>
      {!isManager ? (
        <Redirect to={{ pathname: '/Home' }} />
      ) : (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={ReceiptIcon} title="Invoices" />
          <CustomerDetails />

          <InvoiceGroup
            prefix={prefix}
            invoices={getUnsentInvoices(invoices)}
            label={`Unsent Invoices: ${getTotal(getUnsentInvoices(invoices))}`}
          />

          <InvoiceGroup
            prefix={prefix}
            invoices={getUnpaidInvoices(invoices)}
            label={`Unpaid Invoices: ${getTotal(getUnpaidInvoices(invoices))}`}
          />

          <InvoiceGroup
            prefix={prefix}
            invoices={getMostRecentlyPaidInvoices(invoices, 10)}
            label="Recent paid Invoices:"
          />

          <NavButtons backTo={prefix ?? '/Home'} />
        </Grid>
      )}
    </>
  )
}
