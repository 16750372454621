import { useAssignment } from '../../../context/AssignmentContext'
import React, { useEffect, useState } from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { useData } from '../../../context/DataContext'
import { MenuButton } from '../../../fragments/Buttons/MenuButton'
import { Grid } from '@mui/material'
import { PageTitle } from '../../../fragments/PageTitle'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

export default function SelectAssignmentProperty({ customerId, assignmentId }) {
  let match = useRouteMatch()
  const [properties, setProperties] = useState()
  const { getCustomerById } = useData()
  const { property, setProperty } = useAssignment()

  useEffect(() => {
    if (getCustomerById) {
      const { properties } = getCustomerById(customerId)
      setProperties(properties)
    }
  }, [getCustomerById])

  const handleClick = () => {
    setProperty(properties[0])
  }

  return (
    <>
      {!!property && (
        <Redirect
          from={match.path}
          to={match.path
            .replace(':customerId', customerId)
            .replace(':assignmentId', assignmentId)
            .replace('/Property/Select', '/AssignmentDateTime')}
          exact
        />
      )}
      {properties?.length && (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={AccessTimeIcon} title="Assignment" />

          <Grid item xs={12}>
            <p>The first known property will be used.</p>
          </Grid>
          <MenuButton onClick={handleClick} label="Continue" />
        </Grid>
      )}
    </>
  )
}
