import React, { useContext, useEffect, useState } from 'react'
import { useData } from './DataContext'
import { format } from 'date-fns'
import nearestDateTime from '../../utilities/nearestDateTime'
import maxRelativeDateTime from '../../utilities/maxRelativeDateTime'
import { useAuthentication } from './AuthenticationContext'

export const VisitContext = React.createContext(undefined)

export const useVisit = () => useContext(VisitContext)

export const VisitProvider = ({
  children,
  visitId,
  customerId,
  visitType: vType,
}) => {
  const [visitCustomerId, setVisitCustomerId] = useState()
  const [totalHours, setTotalHours] = useState(0)
  const [duration, setDuration] = useState('')
  const [timeWorked, setTimeWorked] = useState('')
  const [timeRested, setTimeRested] = useState('')
  const [total, setTotal] = useState(0)
  const [durationInMinutes, setDurationInMinutes] = useState(0)
  const [property, setProperty] = useState()
  const [assignmentId, setAssignmentId] = useState()
  const [user, setUser] = useState()
  const [tasks, setTasks] = useState()
  const [taskTypes, setTaskTypes] = useState()
  const [defaultTaskType, setDefaultTaskType] = useState({})
  const [visitDateTime, setVisitDateTime] = useState({})
  const [visitType, setVisitType] = useState()
  const {
    getVisitById,
    getCustomerById,
    saveVisit,
    getUserById,
    getAssignmentById,
  } = useData()
  const { user: currentUser } = useAuthentication()

  useEffect(() => {
    if (assignmentId) {
      const assignment = getAssignmentById(assignmentId)
      setUser(assignment?.user)
    }
  }, [assignmentId, setUser])

  useEffect(() => {
    if (vType) {
      setVisitType(vType)
    }
  }, [vType, setVisitType])

  useEffect(() => {
    if (customerId) {
      const customer = getCustomerById(customerId)
      if (customer?.customerTaskTypes?.length) {
        setTaskTypes(customer.customerTaskTypes)
        setDefaultTaskType(
          customer.customerTaskTypes.find(
            ({ taskType }) =>
              taskType.taskTypeName === user?.defaultTaskType.taskTypeName
          )
        )
      }
    }
    let visit
    if (visitId) {
      visit = getVisitById(visitId)
    }
    if (visit) {
      const { property, visitDate, startTime, finishTime, tasks, user } = visit
      setVisitDateTime({
        visitDate: new Date(visitDate),
        startTime: new Date(`${visitDate} ${startTime}`),
        finishTime: tasks?.length
          ? new Date(`${visitDate} ${finishTime}`)
          : maxRelativeDateTime(
              nearestDateTime(15),
              new Date(`${visitDate} ${startTime}`)
            ),
      })
      setProperty({ ...property })
      setUser({ ...user })
      setTasks([...tasks])
      setVisitCustomerId(visit.customerId)
    } else {
      setVisitDateTime({
        visitDate: nearestDateTime(15),
        startTime: nearestDateTime(15),
        finishTime: nearestDateTime(15),
      })
      // Now get first property from customer as default
      if (customerId) {
        const customer = getCustomerById(customerId)
        if (customer?.properties?.length) {
          setProperty(customer.properties[0])
        }
        setVisitCustomerId(customerId)
      }
      setTasks([])
      let userId = currentUser.id
      if (assignmentId) {
        const assignment = getAssignmentById(assignmentId)
        userId = assignment?.user.id
      }
      setUser(getUserById(userId))
    }
  }, [visitId, user?.email])

  useEffect(() => {
    if (visitDateTime?.startTime && visitDateTime?.finishTime) {
      const durationInMinutes =
        (visitDateTime?.finishTime - visitDateTime?.startTime) / 60000
      setDurationInMinutes(durationInMinutes)
    }
  }, [visitDateTime])

  const formatTime = (timeInMinutes) => {
    const minutes = timeInMinutes % 60
    const hours = Math.floor(timeInMinutes / 60)
    return `${hours} hour${hours === 1 ? '' : 's'}, ${minutes} minute${
      minutes === 1 ? '' : 's'
    }`
  }

  useEffect(() => {
    setDuration(formatTime(durationInMinutes))
  }, [durationInMinutes])

  useEffect(() => {
    setTimeWorked(formatTime(totalHours * 60))
  }, [totalHours])

  useEffect(() => {
    const timeRestedInMinutes = durationInMinutes - totalHours * 60
    setTimeRested(timeRestedInMinutes ? formatTime(timeRestedInMinutes) : '')
  }, [totalHours, durationInMinutes])

  useEffect(() => {
    if (Array.isArray(tasks)) {
      const totals = tasks
        ?.filter(({ isMarkedForDeletion }) => !isMarkedForDeletion)
        .filter(({ taskType }) => !!taskType)
        .reduce(
          ({ total, totalHours }, task) => {
            return {
              total: total + task.quantity * task.price,
              totalHours: totalHours + task.quantity,
            }
          },
          { total: 0, totalHours: 0 }
        )
      if (totals.total !== total) {
        setTotal(totals.total)
      }
      if (totals.totalHours !== totalHours) {
        setTotalHours(Math.round(totals.totalHours * 100) / 100)
      }
      if (tasks.length) {
        if (tasks.every(({ taskName }) => taskName === 'Other')) {
          setVisitType('Items')
        } else {
          setVisitType(null)
        }
      }
    }
  }, [tasks])

  const save = () => {
    const { visitDate, startTime, finishTime } = visitDateTime
    const visit = {
      id: visitId,
      property,
      visitDate: format(visitDate, 'yyyy-MM-dd'),
      startTime: format(startTime, 'HH:mm:ss'),
      finishTime: format(finishTime, 'HH:mm:ss'),
      tasks: tasks.map(({ cost, ...task }) => ({ ...task, cost: cost || 0 })),
      user,
    }
    saveVisit(visitCustomerId, assignmentId, visit)
  }

  return (
    <VisitContext.Provider
      value={{
        tasks,
        setTasks,
        taskTypes,
        property,
        setProperty,
        user,
        setUser,
        visitDateTime,
        setVisitDateTime,
        visitType,
        setAssignmentId,
        defaultTaskType,
        durationInMinutes,
        duration,
        total,
        totalHours,
        timeWorked,
        timeRested,
        visitId,
        save,
      }}
    >
      {children}
    </VisitContext.Provider>
  )
}
