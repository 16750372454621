import React from 'react'
import SetInnerHTML from './SetInnerHTML'

export default function PrintedInvoice({ invoiceNumber, setLoading }) {
  const url = `${process.env.REACT_APP_API}/invoice`

  return (
    <>
      {!!invoiceNumber && (
        <SetInnerHTML url={`${url}/${invoiceNumber}`} setLoading={setLoading} />
      )}
    </>
  )
}
