import React, { useEffect, useState } from 'react'
import { useData } from '../context/DataContext'
import { styled } from '@mui/material/styles'
import { AssignmentCard } from './AssignmentCard'
import { AssignmentProvider } from '../context/AssignmentContext'
import { isToday } from 'date-fns'
import { v4 as uuid } from 'uuid'

const PREFIX = 'LdgApp-Assignment-Details'

const classes = {
  label: `${PREFIX}-label`,
}

const Root = styled('div')(({ theme }) => ({
  [`.${classes.label}`]: {
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(1.5),
  },
}))

export function AssignmentDetails({
  assignmentId,
  customerId,
  canDelete,
  canEdit,
  onDelete,
}) {
  const { getAssignmentById } = useData()
  const [property, setProperty] = useState()
  const [assignment, setAssignment] = useState()
  const [displayLink, setDisplayLink] = useState(false)

  useEffect(() => {
    setAssignment(getAssignmentById(assignmentId))
  }, [getAssignmentById])

  useEffect(() => {
    if (assignment) {
      setProperty(assignment.property)
      setDisplayLink(!!isToday(new Date(assignment.assignmentDate)))
    }
  }, [assignment])

  return (
    <Root>
      {!!assignmentId && (
        <AssignmentProvider assignmentId={assignmentId}>
          <AssignmentCard
            assignmentId={assignmentId}
            customerId={customerId}
            editLink={
              displayLink
                ? `/Customers/${
                    customerId || property?.customer?.id
                  }/Visits/${uuid()}/Edit/Start?assignmentId=${assignmentId}`
                : ''
            }
            editText={displayLink ? 'Start Assignment' : ''}
            canDelete={canDelete}
            onDelete={() => onDelete(assignmentId)}
            canEdit={canEdit}
          />
        </AssignmentProvider>
      )}
    </Root>
  )
}
