import MenuGroup from './MenuGroup'
import React, { useEffect, useState } from 'react'
import { AssignmentDetails } from './AssignmentDetails'
import { useAuthentication } from '../context/AuthenticationContext'

export default function AssignmentGroup({
  assignments,
  label,
  customerId,
  onDelete,
}) {
  const { isManager } = useAuthentication()
  const [filteredAssignments, setFilteredAssignments] = useState()

  useEffect(() => {
    if (customerId) {
      assignments = assignments.filter(
        ({ property }) => property?.customer?.id === customerId
      )
    }
    setFilteredAssignments(assignments)
  }, [assignments, customerId])

  return (
    <>
      {!!filteredAssignments?.length && (
        <MenuGroup label={label}>
          {filteredAssignments.map((assignment) =>
            assignment ? (
              <AssignmentDetails
                key={`assignment-${assignment.id}`}
                customerId={customerId}
                assignmentId={assignment.id}
                canDelete={isManager}
                onDelete={onDelete}
                canEdit={isManager}
              />
            ) : null
          )}
        </MenuGroup>
      )}
    </>
  )
}
