import React from 'react'
import { Grid } from '@mui/material'
import WorkerIcon from '@mui/icons-material/AccountBox'
import ReportIcon from '@mui/icons-material/Summarize'
import { PageTitle } from '../../fragments/PageTitle'
import { WorkerCard } from '../../fragments/WorkerCard'
import { useAuthentication } from '../../context/AuthenticationContext'
import { useParams } from 'react-router-dom'
import { WorkerProvider } from '../../context/WorkerContext'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { MenuButton } from '../../fragments/Buttons/MenuButton'

export default function Worker() {
  const { user } = useAuthentication()
  const { workerId } = useParams()

  return (
    <Grid container direction="column" spacing={2} alignContent="stretch">
      <PageTitle
        icon={WorkerIcon}
        title={user?.id === workerId ? 'Profile' : 'Worker'}
      />
      <WorkerProvider workerId={workerId}>
        <WorkerCard workerId={workerId} />
      </WorkerProvider>
      <MenuButton
        to={`/Workers/${workerId}/Reports`}
        icon={ReportIcon}
        label="Worker Reports"
      />
      <NavButtons backTo={user?.id === workerId ? '/Home' : '/Workers'} />
    </Grid>
  )
}
