import NaturePeopleIcon from '@mui/icons-material/NaturePeople'
import { v4 as uuid } from 'uuid'
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useVisit } from '../../../context/VisitContext'
import { PageTitle } from '../../../fragments/PageTitle'
import { NavButtons } from '../../../fragments/Buttons/NavButtons'
import { useRouteMatch } from 'react-router-dom'
import { VisitCard } from '../../../fragments/VisitCard'
import { useAuthentication } from '../../../context/AuthenticationContext'

export default function VisitFinish({ customerId, visitId }) {
  const { isManager, user } = useAuthentication()
  const {
    visitDateTime,
    visitType,
    tasks,
    setTasks,
    durationInMinutes,
    taskTypes,
    defaultTaskType,
  } = useVisit()
  const { visitDate } = visitDateTime || {}

  let match = useRouteMatch()

  const path = match.path
    .replace(':customerId', customerId)
    .replace(':visitId', visitId)

  const backTo = isManager
    ? path.substring(0, path.lastIndexOf('/Visits/') + 8)
    : '/Home'

  const continueTo = path.substring(0, path.lastIndexOf('/Edit/') + 6) + 'Save'

  useEffect(() => {
    if (tasks && !tasks.length && durationInMinutes && taskTypes?.length) {
      const { taskTypeName: taskName, price } = defaultTaskType
      const hours = durationInMinutes % 60
      const minutes = durationInMinutes - hours * 60
      const task = {
        id: uuid(),
        taskName,
        price,
        cost: user.rate || 0,
        taskType: defaultTaskType,
        quantity: hours + minutes / 60,
      }
      setTasks([task])
    }
  }, [durationInMinutes, taskTypes, defaultTaskType])

  return (
    <>
      {visitDate && (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={NaturePeopleIcon} title="Visit save" />

          <Grid item xs={12}>
            <p>Please check this is correct before you press save:</p>
          </Grid>
          {isManager && (
            <VisitCard
              editText="Edit if required"
              editLink={
                path.substring(0, path.lastIndexOf('/Edit/') + 6) +
                (visitType ? 'VisitItems' : 'VisitDateTime')
              }
            />
          )}
          {!isManager && <VisitCard />}
          <NavButtons
            backTo={backTo}
            backLabel="Cancel"
            continueTo={continueTo}
            continueLabel="Save"
          />
        </Grid>
      )}
    </>
  )
}
