import getCardStyle from '../../styles/card'
import React, { useEffect, useState } from 'react'
import formatName from '../../utilities/formatName'
import { Button, Card, CardContent, Grid, IconButton } from '@mui/material'
import { VisitContent } from './VisitContent'
import { useAssignment } from '../context/AssignmentContext'
import { useAuthentication } from '../context/AuthenticationContext'
import EditIcon from '@mui/icons-material/Edit'
import DirectionsIcon from '@mui/icons-material/Directions'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link } from 'react-router-dom'
import formatAddress from '../../utilities/formatAddress'
import { StyledModal } from './StyledModal'

const { classes, Root } = getCardStyle('LdgApp-Assignment-Details', '#FDCEC7')

export function AssignmentCard({
  assignmentId,
  customerId,
  editLink,
  editText,
  canDelete,
  canEdit,
  onDelete = () => {},
}) {
  const { isManager, user: currentUser } = useAuthentication()
  const { property, assignmentDateTime, user } = useAssignment()
  const { assignmentDate, startTime, finishTime } = assignmentDateTime || {}
  const [displayCard, setDisplayCard] = useState()
  const [visitor, setVisitor] = useState('')
  const [deleteModelOpen, setDeleteModelOpen] = useState(false)

  useEffect(() => {
    if (!!startTime && startTime.toDateString() !== 'Invalid Date') {
      setDisplayCard(true)
    } else {
      setDisplayCard(false)
    }
  }, [startTime])

  useEffect(() => {
    setVisitor(formatName(user))
  }, [user])

  const handleDirectionsClick = () => {
    window.location.href = `https://www.google.com/maps/search/${encodeURIComponent(
      formatAddress(property.address)
    )}`
  }

  const handleDeleteRequest = () => {
    setDeleteModelOpen(true)
  }

  const handleDelete = () => {
    onDelete()
  }

  return (
    <Root>
      {!!displayCard && (
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <VisitContent
                visitDate={assignmentDate}
                startTime={startTime}
                finishTime={finishTime}
                property={property}
                customerId={customerId || property?.customer?.id}
                showAddress={!customerId}
              />
              {!customerId && !!property?.address && (
                <IconButton
                  edge="end"
                  variant="contained"
                  color={'primary'}
                  onClick={handleDirectionsClick}
                  tabIndex={0}
                >
                  <DirectionsIcon />
                </IconButton>
              )}

              {isManager && (
                <>
                  {!!visitor && (
                    <>
                      <br />
                      <br />
                      <strong>Visitor: {visitor}</strong>
                    </>
                  )}
                </>
              )}

              {(!!canDelete || !!canEdit) && (
                <>
                  <br />
                  <br />
                  <div className={classes.buttons}>
                    {!!canEdit && (
                      <IconButton
                        edge="end"
                        variant="contained"
                        color={'primary'}
                        component={Link}
                        to={`/Customers/${
                          customerId || property?.customer?.id
                        }/Assignments/${assignmentId}/Edit/Check`}
                        tabIndex={0}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {!!canDelete && (
                      <IconButton
                        edge="end"
                        variant="contained"
                        color={'primary'}
                        onClick={handleDeleteRequest}
                        tabIndex={0}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                </>
              )}
            </CardContent>
            <div className={classes.buttons}>
              {!!editLink && (
                <>
                  {!!editText ? (
                    <Button
                      startIcon={<EditIcon />}
                      variant="contained"
                      color={
                        user?.email !== currentUser?.email
                          ? 'primary'
                          : 'secondary'
                      }
                      component={Link}
                      tabIndex={0}
                      to={editLink}
                      fullWidth={true}
                      size="large"
                    >
                      {editText}
                    </Button>
                  ) : (
                    <IconButton
                      edge="end"
                      variant="contained"
                      color={'primary'}
                      to={editLink}
                      component={Link}
                      tabIndex={0}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </>
              )}
            </div>
          </Card>
          <StyledModal
            open={deleteModelOpen}
            setOpen={setDeleteModelOpen}
            title="Are you sure you want to delete the assignment?"
            onClickYes={handleDelete}
          />
        </Grid>
      )}
    </Root>
  )
}
