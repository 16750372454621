import React, { useEffect, useState } from 'react'
import { useAssignment } from '../../../context/AssignmentContext'
import { assignmentDateTimeFields } from '../../../../constants/fields'
import AssignmentEditView from './AssignmentEditView'
import { useData } from '../../../context/DataContext'
import formatName from '../../../../utilities/formatName'
import { dropDownSort } from '../../../../utilities/sort'

export default function AssignmentDateTimeEdit(params) {
  const { users } = useData()
  const { assignmentDateTime, setAssignmentDateTime, user, setUser } =
    useAssignment()
  const [data, setData] = useState({})

  const visitorFields = [
    {
      field: 'visitorId',
      label: 'Visitor',
      type: 'select',
      options: users
        .filter((user) => ['Manager', 'Worker'].includes(user.role.roleName))
        .map((user) => ({
          value: user.id,
          label: formatName(user),
        }))
        .sort(dropDownSort),
    },
  ]

  useEffect(() => {
    if (user && assignmentDateTime) {
      setData({ visitorId: user.id, ...assignmentDateTime })
    }
  }, [])

  useEffect(() => {
    if (data) {
      const { visitorId, ...assignmentDateTime } = data
      const user = users.find((user) => user.id === visitorId)
      setUser(user)
      setAssignmentDateTime(assignmentDateTime)
    }
  }, [data])

  return (
    <AssignmentEditView
      {...params}
      done="Check"
      subHeading="Assignment date and time"
      fields={(assignmentDateTimeFields || []).concat(visitorFields)}
      data={data}
      setData={setData}
    ></AssignmentEditView>
  )
}
