import React from 'react'
import { useWorker } from '../../../context/WorkerContext'
import { contactFields } from '../../../../constants/fields'
import WorkerEditView from './WorkerEditView'
import { useAuthentication } from '../../../context/AuthenticationContext'
import { useParams } from 'react-router-dom'

export default function WorkerContactEdit(params) {
  const { workerId } = useParams()
  const { contact, setContact } = useWorker()
  const { user, isManager } = useAuthentication()
  let fields = [...contactFields]
  if (!isManager || user?.id === workerId) {
    fields = contactFields.filter(({ field }) => field !== 'email')
  }
  return (
    <WorkerEditView
      {...params}
      prev="Name"
      next="Address"
      fields={fields}
      data={contact}
      setData={setContact}
    />
  )
}
