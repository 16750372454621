import React, { useContext, useEffect, useState } from 'react'

export const GlobalContext = React.createContext(undefined)

export const useGlobal = () => useContext(GlobalContext)

export const GlobalProvider = ({ children }) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [error, setError] = useState(false)
  const [online, setOnline] = useState(true)
  const [hasAssignments, setHasAssignments] = useState(false)
  const [hasVisits, setHasVisits] = useState(false)
  const apiUrl = process.env.REACT_APP_API

  useEffect(() => {
    let timeout

    const checkHealth = async () => {
      try {
        const response = await fetch(`${apiUrl}/health`)
        console.log(response)
        const result = await response.json()
        setOnline(result.ok ?? false)
      } catch {
        setOnline(false)
      } finally {
        timeout = setTimeout(checkHealth, 10000)
      }
    }
    checkHealth()
    return () => clearTimeout(timeout)
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        menuVisible,
        setMenuVisible,
        syncing,
        setSyncing,
        error,
        setError,
        hasAssignments,
        setHasAssignments,
        hasVisits,
        setHasVisits,
        online,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
