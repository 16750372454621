import React, { useContext, useEffect, useState } from 'react'
import { useData } from './DataContext'
import { format } from 'date-fns'
import nearestDateTime from '../../utilities/nearestDateTime'
import { useAuthentication } from './AuthenticationContext'

export const AssignmentContext = React.createContext(undefined)

export const useAssignment = () => useContext(AssignmentContext)

export const AssignmentProvider = ({ children, assignmentId, customerId }) => {
  const [isNewAssignment, setIsNewAssignment] = useState(true)
  const [user, setUser] = useState()
  const [property, setProperty] = useState()
  const [assignmentDateTime, setAssignmentDateTime] = useState({})
  const { getAssignmentById, getCustomerById, getUserById, saveAssignment } =
    useData()
  const { user: currentUser } = useAuthentication()

  useEffect(() => {
    if (assignmentId) {
      const assignment = getAssignmentById(assignmentId)
      if (assignment) {
        setIsNewAssignment(false)
        const { property, assignmentDate, startTime, finishTime, user } =
          assignment
        const assignmentTime = new Date(`${assignmentDate} ${startTime}`)
        setAssignmentDateTime({
          assignmentDate: new Date(assignmentTime),
          startTime: new Date(assignmentTime),
          finishTime: new Date(`${assignmentDate} ${finishTime}`),
        })
        setProperty({ ...property })
        setUser({ ...user })
      } else {
        const assignmentTime = nearestDateTime(15)
        setAssignmentDateTime({
          assignmentDate: new Date(assignmentTime),
          startTime: new Date(assignmentTime),
          finishTime: new Date(assignmentTime),
        })
        // Now get first property from customer as default
        if (customerId) {
          const customer = getCustomerById(customerId)
          if (customer?.properties?.length) {
            const property = { ...customer.properties[0] }
            let { id, title, firstName, lastName, email, phone } = customer
            property.customer = { id, title, firstName, lastName, email, phone }
            setProperty(property)
          }
        }
        setUser(getUserById(currentUser.id))
      }
    }
  }, [assignmentId])

  const save = () => {
    const { assignmentDate, startTime, finishTime } = assignmentDateTime
    const assignment = getAssignmentById(assignmentId) || { id: assignmentId }
    const assignmentToSave = {
      ...assignment,
      property,
      assignmentDate: format(assignmentDate, 'yyyy-MM-dd'),
      startTime: format(startTime, 'HH:mm:ss'),
      finishTime: format(finishTime, 'HH:mm:ss'),
      user,
    }
    saveAssignment(assignmentToSave)
  }

  return (
    <AssignmentContext.Provider
      value={{
        property,
        setProperty,
        user,
        setUser,
        save,
        assignmentDateTime,
        setAssignmentDateTime,
        isNewAssignment,
      }}
    >
      {children}
    </AssignmentContext.Provider>
  )
}
