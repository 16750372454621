import { PageTitle } from '../../fragments/PageTitle'
import ReportIcon from '@mui/icons-material/Summarize'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { MenuButton } from '../../fragments/Buttons/MenuButton'
import { useData } from '../../context/DataContext'

export default function Reports() {
  const { reports } = useData()
  const [years, setYears] = useState([])

  useEffect(() => {
    if (reports?.invoicesDataByMonth?.length) {
      const { invoicesDataByMonth: months } = reports
      const yearsStart = months[0].year
      const yearsEnd = months[months.length - 1].year
      const years = new Array(yearsEnd - yearsStart + 1)
        .fill(null)
        .map((_, index) => ({
          start: yearsStart + index,
          end: yearsStart + index + 1,
        }))
      setYears([...years])
    }
  }, [reports])

  return (
    <Grid container direction="column" spacing={2} alignContent="stretch">
      <PageTitle icon={ReportIcon} title="Reports" />
      {years.map((year) => (
        <MenuButton
          key={`report-${year.start}`}
          to={`/Reports/${year.start}`}
          icon={ReportIcon}
          label={`${year.start} - ${year.end}`}
        />
      ))}
      <NavButtons backTo={'/Home'} />
    </Grid>
  )
}
