import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Grid } from '@mui/material'
import React from 'react'
import { useAssignment } from '../../../context/AssignmentContext'
import { PageTitle } from '../../../fragments/PageTitle'
import { NavButtons } from '../../../fragments/Buttons/NavButtons'
import { useRouteMatch } from 'react-router-dom'
import { AssignmentCard } from '../../../fragments/AssignmentCard'

export default function AssignmentCreate({ customerId, assignmentId }) {
  const { assignmentDateTime } = useAssignment()
  const { assignmentDate } = assignmentDateTime || {}

  let match = useRouteMatch()

  const path = match.path
    .replace(':customerId', customerId)
    .replace(':assignmentId', assignmentId)

  const backTo = assignmentId
    ? '/Assignments'
    : path.substring(0, path.lastIndexOf('/Assignments/'))

  const continueTo = path.substring(0, path.lastIndexOf('/Edit/') + 6) + 'Save'

  return (
    <>
      {assignmentDate && (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={AccessTimeIcon} title="Assignment Create" />

          <Grid item xs={12}>
            <p>Please check this is correct before you press start:</p>
          </Grid>
          <AssignmentCard customerId={customerId} />
          <NavButtons
            backTo={backTo}
            backLabel="Cancel"
            continueTo={continueTo}
            continueLabel="Create"
          />
        </Grid>
      )}
    </>
  )
}
