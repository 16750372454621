import { PageTitle } from '../../fragments/PageTitle'
import ReportIcon from '@mui/icons-material/Summarize'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { getMonthName } from '../../../utilities/getMonthName'
import { MenuButton } from '../../fragments/Buttons/MenuButton'
import { useParams } from 'react-router-dom'
import MenuGroup from '../../fragments/MenuGroup'
import { useData } from '../../context/DataContext'
import { sort } from '../../../utilities/sort'

function ReportRow({ month = 0, year = {} }) {
  const monthName = getMonthName(month)
  const label = `${monthName} ${year}`
  return (
    <MenuButton
      to={`/Reports/${year}/${month}`}
      icon={ReportIcon}
      label={label}
      color="secondary"
    />
  )
}

export default function ReportsForYear() {
  const { reports } = useData()
  const { yearStart } = useParams()
  const [months, setMonths] = useState([])
  const year = { start: parseInt(yearStart), end: parseInt(yearStart) + 1 }

  useEffect(() => {
    if (reports?.invoicesDataByMonth?.length) {
      const months = reports.invoicesDataByMonth.filter(
        (monthData) =>
          (monthData.month >= 3 && monthData.year === year.start) ||
          (monthData.month <= 2 && monthData.year === year.end)
      )
      setMonths(months)
    }
  }, [reports])

  return (
    <Grid container direction="column" spacing={2} alignContent="stretch">
      <PageTitle icon={ReportIcon} title="Reports" />
      <MenuGroup label={`${year.start} - ${year.end}`}>
        {months.map(({ month, year }) => (
          <ReportRow key={`report-${month}`} month={month} year={year} />
        ))}
        <MenuButton
          to={`/Reports/${year.start}/${year.end}`}
          icon={ReportIcon}
          label={`Summary ${year.start} - ${year.end}`}
        />
      </MenuGroup>
      <NavButtons backTo={'/Reports'} />
    </Grid>
  )
}
