import MenuGroup from './MenuGroup'
import { sort } from '../../utilities/sort'
import React from 'react'
import { MenuButton } from './Buttons/MenuButton'
import ReceiptIcon from '@mui/icons-material/Receipt'

function InvoiceRow({ invoice, prefix }) {
  const { id = '', invoiceNumber = '' } = invoice || {}

  return (
    <MenuButton
      to={`${prefix ? prefix : ''}/Invoices/${id}`}
      icon={ReceiptIcon}
      label={invoiceNumber}
      color="secondary"
    />
  )
}

export default function InvoiceGroup({ invoices, label, prefix }) {
  return (
    <>
      {!!invoices?.length && (
        <MenuGroup label={label}>
          {invoices
            .sort(({ invoiceNumber: a }, { invoiceNumber: b }) => sort(b, a))
            .map((invoice) => (
              <InvoiceRow
                key={`invoice-${invoice.id}`}
                invoice={invoice}
                prefix={prefix}
              />
            ))}
        </MenuGroup>
      )}
    </>
  )
}
