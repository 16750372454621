import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { PageTitle } from '../../fragments/PageTitle'
import { useParams } from 'react-router-dom'
import { useData } from '../../context/DataContext'
import { CustomerDetails } from '../../fragments/CustomerDetails'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { useAuthentication } from '../../context/AuthenticationContext'
import AssignmentGroup from '../../fragments/AssignmentGroup'

export default function Assignments() {
  const { getAssignments, deleteAssignment } = useData()
  const { user: currentUser } = useAuthentication()
  const { customerId } = useParams()
  const [assignments, setAssignments] = useState()
  const [prefix, setPrefix] = useState()

  useEffect(() => {
    if (customerId) {
      setPrefix(`/Customers/${customerId}`)
    }
  }, [customerId])

  useEffect(() => {
    if (currentUser?.id) {
      setAssignments(
        getAssignments({ userId: currentUser.id }).filter(({ visit }) => !visit)
      )
    }
  }, [getAssignments, currentUser?.id])

  return (
    <>
      <Grid container direction="column" spacing={2} alignContent="stretch">
        <PageTitle icon={AccessTimeIcon} title="Assignments" />
        {!!customerId && <CustomerDetails />}

        {!!assignments?.length && (
          <>
            <AssignmentGroup
              userId={currentUser.id}
              assignments={assignments}
              customerId={customerId}
              onDelete={deleteAssignment}
            />
          </>
        )}

        <NavButtons backTo={prefix ?? '/Home'} />
      </Grid>
    </>
  )
}
