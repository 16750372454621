import { Grid } from '@mui/material'
import { PageTitle } from '../../fragments/PageTitle'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { v4 as uuid } from 'uuid'
import WorkerIcon from '@mui/icons-material/AccountBox'
import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useData } from '../../context/DataContext'
import { MenuButton } from '../../fragments/Buttons/MenuButton'
import MenuGroup from '../../fragments/MenuGroup'
import { useAuthentication } from '../../context/AuthenticationContext'
import { Redirect } from 'react-router-dom'

function ViewWorkerRow({ worker }) {
  const { id = '', title = '', firstName = '', lastName = '' } = worker || {}

  return (
    <MenuButton
      to={`/Workers/${id}`}
      icon={WorkerIcon}
      label={[title, firstName, lastName].filter((item) => !!item).join(' ')}
      color="secondary"
    />
  )
}

export default function Workers() {
  const { users } = useData()
  const { user: myself, isManager } = useAuthentication()
  const [workers, setWorkers] = useState()
  const [accountWorkers, setAccountWorkers] = useState()

  useEffect(() => {
    const workers = users.filter(
      (user) =>
        user.id !== myself.id &&
        ['Manager', 'Worker'].includes(user.role.roleName)
    )
    setWorkers(workers)
    const accountWorkers = users.filter(
      (user) => user.id !== myself.id && user.role.roleName === 'Accounts'
    )
    setAccountWorkers(accountWorkers)
  }, [users])

  return (
    <>
      {!isManager ? (
        <Redirect to={{ pathname: '/Home' }} />
      ) : (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={WorkerIcon} title="Workers" />

          {!!workers?.length && (
            <MenuGroup label={'Workers:'}>
              {workers.map((worker) => (
                <ViewWorkerRow key={`worker-${worker.id}`} worker={worker} />
              ))}{' '}
            </MenuGroup>
          )}

          {!!accountWorkers?.length && (
            <MenuGroup label={'Accountants:'}>
              {accountWorkers.map((accountWorker) => (
                <ViewWorkerRow
                  key={`account-worker-${accountWorker.id}`}
                  worker={accountWorker}
                />
              ))}{' '}
            </MenuGroup>
          )}

          <MenuGroup label={' '}>
            <MenuButton
              to={`/Workers/${uuid()}/Edit`}
              icon={AddIcon}
              label="New Worker"
            />
          </MenuGroup>

          <NavButtons backTo={'/Home'} />
        </Grid>
      )}
    </>
  )
}
