import Home from './components/pages/Home'
import Visits from './components/pages/Visits/Visits'
import Assignments from './components/pages/Assignments/Assignments'
import Invoices from './components/pages/Invoices/Invoices'
import Invoice from './components/pages/Invoices/Invoice'
import Customers from './components/pages/Customers/Customers'
import Customer from './components/pages/Customers/Customer'
import CustomerEdit from './components/pages/Customers/CustomerEdit/CustomerEdit'
import Logout from './components/pages/Logout'
import VisitEdit from './components/pages/Visits/VisitEdit/VisitEdit'
import InvoiceEdit from './components/pages/Invoices/InvoiceEdit/InvoiceEdit'
import Reports from './components/pages/Reports/Reports'
import ReportsForYear from './components/pages/Reports/ReportsForYear'
import ViewReport from './components/pages/Reports/ViewReport'
import AssignmentEdit from './components/pages/Assignments/AssignmentEdit/AssignmentEdit'
import Workers from './components/pages/Workers/Workers'
import Worker from './components/pages/Workers/Worker'
import WorkerEdit from './components/pages/Workers/WorkerEdit/WorkerEdit'

const routes = [
  {
    path: 'Home',
    component: Home,
    exact: true,
  },

  // Assignments
  // ***********
  {
    path: 'Assignments',
    component: Assignments,
    exact: true,
  },

  // Visits
  // ******
  {
    path: 'Visits',
    component: Visits,
    exact: true,
  },

  // Invoices
  // ********
  {
    path: 'Invoices',
    component: Invoices,
    exact: true,
  },
  {
    path: `Invoices/:invoiceId`,
    component: Invoice,
    exact: true,
  },

  // Reports
  // *******
  {
    path: 'Reports',
    component: Reports,
    exact: true,
  },
  {
    path: 'Reports/:yearStart',
    component: ReportsForYear,
    exact: true,
  },
  {
    path: 'Reports/:yearStart/:month',
    component: ViewReport,
    exact: true,
  },

  // Workers
  // *******
  {
    path: 'Workers',
    component: Workers,
    exact: true,
  },
  {
    path: `Workers/:workerId`,
    component: Worker,
    exact: true,
  },
  {
    path: `Workers/:workerId/Edit`,
    component: WorkerEdit,
  },

  // Customers
  // *********
  {
    path: 'Customers',
    component: Customers,
    exact: true,
  },
  {
    path: `Customers/:customerId`,
    component: Customer,
    exact: true,
  },
  {
    path: `Customers/:customerId/Edit`,
    component: CustomerEdit,
  },
  {
    path: `Customers/:customerId/BillPayer/:billPayerId/Edit`,
    component: CustomerEdit,
  },
  {
    path: `Customers/:customerId/Invoices`,
    component: Invoices,
    exact: true,
  },
  {
    path: `Customers/:customerId/Invoices/:invoiceId`,
    component: Invoice,
    exact: true,
  },
  {
    path: `Customers/:customerId/Invoices/:invoiceId/Edit`,
    component: InvoiceEdit,
  },
  {
    path: `Customers/:customerId/Visits`,
    component: Visits,
    exact: true,
  },
  {
    path: `Customers/:customerId/Visits/:visitId/Edit`,
    component: VisitEdit,
  },
  {
    path: `Customers/:customerId/Assignments`,
    component: Assignments,
    exact: true,
  },
  {
    path: `Customers/:customerId/Assignments/:assignmentId/Edit`,
    component: AssignmentEdit,
  },

  // Logout
  // ******
  {
    path: 'Logout',
    component: Logout,
    exact: true,
  },
]

export default routes
