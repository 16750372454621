import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { ccyFormat } from '../../utilities/ccyFormat'
import { useAuthentication } from '../context/AuthenticationContext'

export function VisitTaskRow({ task, totalIsVisible = false }) {
  const { isManager } = useAuthentication()
  return (
    <>
      {isNaN(task?.quantity) ? null : (
        <TableRow>
          <TableCell style={{ padding: 0, maxWidth: '6em' }} align="left">
            {task.taskType ? task.taskName : task.description}
            {!!task.description && task.taskType && (
              <>
                <br />
                <em>{task.description}</em>
              </>
            )}
          </TableCell>
          <TableCell style={{ padding: 0 }} align="right">
            {task.taskType ? task.quantity.toFixed(2) : ''}
          </TableCell>
          {totalIsVisible && isManager && (
            <TableCell style={{ padding: 0 }} align="right">
              {task.price ? ccyFormat(task.price * task.quantity) : ''}
            </TableCell>
          )}
          {totalIsVisible && !isManager && (
            <TableCell style={{ padding: 0 }} align="right">
              {task.cost ? ccyFormat(task.cost * task.quantity) : ''}
            </TableCell>
          )}
        </TableRow>
      )}
    </>
  )
}
