import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NaturePeopleIcon from '@mui/icons-material/NaturePeople'
import { PageTitle } from '../../fragments/PageTitle'
import { useParams } from 'react-router-dom'
import { useData } from '../../context/DataContext'
import { CustomerDetails } from '../../fragments/CustomerDetails'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { visitSort } from '../../../utilities/sort'
import VisitGroup from '../../fragments/VisitGroup'
import { useAuthentication } from '../../context/AuthenticationContext'

export default function Visits() {
  const { getVisitsYetToBeInvoiced, deleteVisit } = useData()
  const { user, isManager } = useAuthentication()
  const { customerId } = useParams()
  const [visits, setVisits] = useState()
  const [prefix, setPrefix] = useState()

  useEffect(() => {
    if (customerId) {
      setPrefix(`/Customers/${customerId}`)
    }
  }, [customerId])

  useEffect(() => {
    setVisits(getVisitsYetToBeInvoiced({ customerId }))
  }, [getVisitsYetToBeInvoiced, customerId])

  return (
    <>
      <Grid container direction="column" spacing={2} alignContent="stretch">
        <PageTitle icon={NaturePeopleIcon} title="Visits" />
        <CustomerDetails />

        {!!visits?.length && (
          <>
            <VisitGroup
              customerId={customerId}
              visits={visits
                .filter(
                  (visit) =>
                    !visit.datePaid &&
                    visit.tasks?.length === 0 &&
                    visit.user?.email === user.email
                )
                .sort(visitSort)}
              label="Current visit:"
              canDelete={isManager}
              onDelete={deleteVisit}
            />
            <VisitGroup
              customerId={customerId}
              visits={visits
                .filter(
                  (visit) =>
                    !visit.datePaid &&
                    visit.tasks?.length === 0 &&
                    visit.user?.email !== user.email
                )
                .sort(visitSort)}
              label="Other visits in progress:"
              canDelete={isManager}
              onDelete={deleteVisit}
            />
            <VisitGroup
              customerId={customerId}
              visits={visits
                .filter((visit) => !visit.datePaid && visit.tasks?.length)
                .sort(visitSort)}
              label="Visits not yet invoiced:"
              canDelete={isManager}
              onDelete={deleteVisit}
            />
          </>
        )}

        <NavButtons backTo={prefix ?? '/Home'} />
      </Grid>
    </>
  )
}
