import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { WorkerProvider } from '../../../context/WorkerContext'
import NotFoundComponent from '../../NotFoundComponent'
import WorkerNameEdit from './WorkerNameEdit'
import WorkerContactEdit from './WorkerContactEdit'
import WorkerAddressEdit from './WorkerAddressEdit'
import WorkerSave from './WorkerSave'
import WorkerTaskTypeAndRateEdit from './WorkerTaskTypeAndRateEdit'

export default function WorkerEdit() {
  let match = useRouteMatch()
  const params = useParams()
  return (
    <WorkerProvider {...params}>
      <Switch>
        <Route path={`${match.path}/Name`} exact>
          <WorkerNameEdit {...params} />
        </Route>
        <Route path={`${match.path}/Contact`} exact>
          <WorkerContactEdit {...params} />
        </Route>
        <Route path={`${match.path}/Address`} exact>
          <WorkerAddressEdit {...params} />
        </Route>
        <Route path={`${match.path}/TaskTypeAndRate`} exact>
          <WorkerTaskTypeAndRateEdit {...params} />
        </Route>
        <Route path={`${match.path}/Save`} exact>
          <WorkerSave {...params} />
        </Route>
        <Redirect from={match.path} to={`${match.url}/Name`} exact />
        <Route component={NotFoundComponent} />
      </Switch>
    </WorkerProvider>
  )
}
