import React, { useContext, useEffect, useRef, useState } from 'react'
import useFetch from 'react-fetch-hook'
import { v4 as uuid } from 'uuid'
import { sort } from '../../utilities/sort'
import { useAuthentication } from './AuthenticationContext'
import { useGlobal } from './GlobalContext'

export const DataContext = React.createContext(undefined)

export const useData = () => useContext(DataContext)

const apiUrl = process.env.REACT_APP_API

export const DataProvider = ({ children }) => {
  const updatedCustomers = useRef([])
  const updatedAssignments = useRef([])
  const updatedUsers = useRef([])
  const {
    token,
    user: currentUser,
    isManager,
    isAccounts,
  } = useAuthentication()

  const [reports, setReports] = useState([])
  const [workersReports, setWorkersReports] = useState([])
  const [taskTypes, setTaskTypes] = useState([])
  const [customers, setCustomers] = useState([])
  const [users, setUsers] = useState([])
  const [assignments, setAssignments] = useState([])
  const [loading, setLoading] = useState(true)
  const [customerToSave, setCustomerToSave] = useState()
  const [assignmentToSave, setAssignmentToSave] = useState()
  const [numberOfPendingUpdates, setNumberOfPendingUpdates] = useState(0)
  const [syncTick, setSyncTick] = useState(0)
  const { setSyncing, setError, setHasAssignments, setHasVisits, online } =
    useGlobal()

  const getPostRequestOptions = ({ body }) => {
    return {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  }

  const getDeleteRequestOptions = () => {
    return {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }

  const { data } = currentUser
    ? useFetch(`${apiUrl}/api/customers?includeVisits=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : { data: [] }

  const { data: taskTypeData } = currentUser
    ? useFetch(`${apiUrl}/task-types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : { data: [] }

  const { data: userData } = currentUser
    ? useFetch(`${apiUrl}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : { data: [] }

  const { data: assignmentData } = currentUser
    ? useFetch(`${apiUrl}/api/assignments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : { data: [] }

  const { data: reportData } =
    isManager || isAccounts
      ? useFetch(`${apiUrl}/reports/invoices/data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      : { data: [] }

  const { data: workerReportData } = currentUser
    ? useFetch(`${apiUrl}/reports/workers/data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : { data: [] }

  useEffect(() => {
    if (numberOfPendingUpdates) {
      setSyncing(true)
    } else {
      setSyncing(false)
    }
  }, [numberOfPendingUpdates])

  useEffect(() => {
    if (Array.isArray(data)) {
      setCustomers((customers) => {
        return [
          // All the customers not returned from the fetch
          ...customers.filter(
            ({ id }) =>
              !data.find((fetchedCustomer) => fetchedCustomer.id === id)
          ),
          // All the customers returned from the fetch
          ...data.map((fetchedCustomer) => {
            const currentCustomer = customers.find(
              ({ id }) => id === fetchedCustomer.id
            )
            if (currentCustomer) {
              // Updated customer
              return { ...currentCustomer, ...fetchedCustomer }
            } else {
              // New customer
              return fetchedCustomer
            }
          }),
        ]
      })
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (Array.isArray(taskTypeData)) {
      setTaskTypes(taskTypeData)
    }
  }, [taskTypeData])

  useEffect(() => {
    if (Array.isArray(userData)) {
      setUsers(userData)
    }
  }, [userData])

  useEffect(() => {
    if (Array.isArray(assignmentData)) {
      setAssignments(assignmentData)
      setHasAssignments(assignmentData.length > 0)
    }
  }, [assignmentData])

  useEffect(() => {
    if (Array.isArray(customers)) {
      const visits = getVisitsYetToBeInvoiced()
      setHasVisits(visits.length > 0)
    }
  }, [customers])

  useEffect(() => {
    if (reportData && Array.isArray(reportData.invoicesDataByMonth)) {
      setReports(reportData)
    }
  }, [reportData])

  useEffect(() => {
    if (workerReportData && Array.isArray(workerReportData)) {
      setWorkersReports(workerReportData)
    }
  }, [workerReportData])

  const syncCustomer = (customerId) => {
    if (!updatedCustomers.current.includes(customerId)) {
      setNumberOfPendingUpdates(
        (numberOfPendingUpdates) => numberOfPendingUpdates + 1
      )
      updatedCustomers.current.push(customerId)
    }
  }

  const syncAssignment = (assignmentId) => {
    if (!updatedAssignments.current.includes(assignmentId)) {
      setNumberOfPendingUpdates(
        (numberOfPendingUpdates) => numberOfPendingUpdates + 1
      )
      updatedAssignments.current.push(assignmentId)
    }
  }

  const syncUser = (userId) => {
    if (!updatedUsers.current.includes(userId)) {
      setNumberOfPendingUpdates(
        (numberOfPendingUpdates) => numberOfPendingUpdates + 1
      )
      updatedUsers.current.push(userId)
    }
  }

  const saveCustomerById = async (customerId) => {
    try {
      const customerToSave = getCustomerById(customerId)

      const response = await fetch(
        `${apiUrl}/api/customers`,
        getPostRequestOptions({ body: customerToSave })
      )

      setNumberOfPendingUpdates(
        (numberOfPendingUpdates) => numberOfPendingUpdates - 1
      )

      if (response.status !== 200) {
        setError(true)
        syncCustomer(customerId)
      } else {
        const savedCustomer = await response.json()
        if (savedCustomer?.id) {
          setCustomers((customers) =>
            customers.map((customer) =>
              customer.id === savedCustomer.id ? savedCustomer : customer
            )
          )
        } else {
          syncCustomer(customerId)
        }
      }
    } catch {
      syncCustomer(customerId)
    }
  }

  const saveAssignmentById = async (assignmentId) => {
    try {
      const {
        id,
        property,
        user,
        visit,
        isMarkedForDeletion,
        ...assignmentToSave
      } = getAssignmentById(assignmentId)

      let response

      if (isMarkedForDeletion) {
        response = await fetch(
          `${apiUrl}/api/assignments/${assignmentId}`,
          getDeleteRequestOptions()
        )
      } else {
        assignmentToSave.propertyId = property.id
        assignmentToSave.userId = user.id
        if (visit?.id) {
          assignmentToSave.visitId = visit.id
        }

        response = await fetch(
          `${apiUrl}/api/assignments/${assignmentId}`,
          getPostRequestOptions({ body: assignmentToSave })
        )
      }

      setNumberOfPendingUpdates(
        (numberOfPendingUpdates) => numberOfPendingUpdates - 1
      )

      if (response.status !== 200) {
        setError(true)
        syncAssignment(assignmentId)
      } else if (isMarkedForDeletion) {
        setAssignments((assignments) =>
          assignments.filter((assignment) => assignment.id !== assignmentId)
        )
      } else {
        const savedAssignment = await response.json()
        if (savedAssignment?.id) {
          savedAssignment.property = property
          savedAssignment.user = user
          savedAssignment.visit = visit
          setAssignments((assignments) =>
            assignments.map((assignment) =>
              assignment.id === savedAssignment.id
                ? savedAssignment
                : assignment
            )
          )
        } else {
          syncAssignment(assignmentId)
        }
      }
    } catch {
      syncAssignment(assignmentId)
    }
  }

  const saveUserById = async (userId) => {
    try {
      const { id, role, defaultTaskType, ...userToSave } = getUserById(userId)

      if (userToSave.address && !userToSave.address.id) {
        userToSave.address.id = uuid()
      }
      userToSave.roleId = role?.id
      userToSave.defaultTaskTypeId = defaultTaskType?.id

      const response = await fetch(
        `${apiUrl}/api/users/${userId}`,
        getPostRequestOptions({ body: userToSave })
      )

      setNumberOfPendingUpdates(
        (numberOfPendingUpdates) => numberOfPendingUpdates - 1
      )

      if (response.status !== 200) {
        setError(true)
        syncUser(userId)
      } else {
        const savedUser = await response.json()
        if (savedUser?.id) {
          setUsers((users) =>
            users.map((user) => (user.id === savedUser.id ? savedUser : user))
          )
        } else {
          syncUser(userId)
        }
      }
    } catch {
      syncUser(userId)
    }
  }

  useEffect(() => {
    if (online && syncTick) {
      while (updatedCustomers.current.length) {
        const customerId = updatedCustomers.current.pop()
        saveCustomerById(customerId)
      }
      while (updatedAssignments.current.length) {
        const assignmentId = updatedAssignments.current.pop()
        saveAssignmentById(assignmentId)
      }
      while (updatedUsers.current.length) {
        const userId = updatedUsers.current.pop()
        saveUserById(userId)
      }
    }
  }, [syncTick, online])

  // Sync customer data
  useEffect(() => {
    let interval

    const processSync = async () => {
      interval = setInterval(() => setSyncTick((tick) => tick + 1), 10000)
    }

    processSync()
    return () => {
      clearInterval(interval)
    }
  }, [])

  const saveAssignment = (assignmentToSave) => {
    setAssignments((assignments) => {
      let newAssignments
      if (assignments.find(({ id }) => id === assignmentToSave.id)) {
        newAssignments = assignments.map((assignment) =>
          assignment.id === assignmentToSave.id ? assignmentToSave : assignment
        )
      } else {
        newAssignments = [...assignments, assignmentToSave]
      }
      return newAssignments
    })
    syncAssignment(assignmentToSave.id)
    setSyncTick((tick) => tick + 1)
  }

  const deleteAssignment = (assignmentToDeleteId) => {
    const assignmentToDelete = getAssignmentById(assignmentToDeleteId)
    saveAssignment({ ...assignmentToDelete, isMarkedForDeletion: true })
  }

  const deleteVisit = (visitToDeleteId) => {
    const visitToDelete = getVisitById(visitToDeleteId)
    saveVisit(visitToDelete.customerId, undefined, {
      ...visitToDelete,
      isMarkedForDeletion: true,
    })
  }

  const saveUser = (userToSave) => {
    setUsers((users) => {
      let newUsers
      if (users.find(({ id }) => id === userToSave.id)) {
        newUsers = users.map((user) =>
          user.id === userToSave.id ? userToSave : user
        )
      } else {
        newUsers = [...users, userToSave]
      }
      return newUsers
    })
    syncUser(userToSave.id)
    setSyncTick((tick) => tick + 1)
  }

  const saveCustomer = (customerToSave) => {
    setCustomers((customers) => {
      let newCustomers
      if (customers.find(({ id }) => id === customerToSave.id)) {
        newCustomers = customers.map((customer) => {
          if (customer.id === customerToSave.id) {
            customerToSave.properties = customerToSave?.properties?.map(
              ({ address, ...property }) =>
                address.id === customerToSave.address.id
                  ? { ...property, address: { ...customerToSave.address } }
                  : { ...property, address }
            )
            return customerToSave
          } else {
            return customer
          }
        })
      } else {
        if (!customerToSave.address.id) {
          customerToSave.address.id = uuid()
        }
        newCustomers = [
          ...customers,
          {
            // Remember to initialise the following if they don't exist
            properties: [{ id: uuid(), address: customerToSave.address }],
            visitsYetToBeInvoiced: [],
            invoices: [],
            customerTaskTypes: taskTypes.map((taskType) => {
              const { id, ...customerTaskType } = taskType
              return {
                ...customerTaskType,
                taskType,
              }
            }),
            ...customerToSave,
          },
        ]
      }
      return newCustomers
    })
    // Initiate save as soon as possible
    syncCustomer(customerToSave.id)
    setSyncTick((tick) => tick + 1)
  }

  const getUserById = (userId) => {
    if (users?.length) {
      return users.find(({ id }) => id === userId)
    }
  }

  const getAssignments = () => {
    return assignments
  }

  const getAssignmentById = (assignmentId) => {
    if (assignments?.length) {
      return assignments.find(({ id }) => id === assignmentId)
    }
  }

  const getAssignmentsByUserId = (userId) => {
    if (assignments?.length) {
      return assignments.filter(({ user }) => user.id === userId)
    }
  }

  const getAssignmentsByCustomerId = (customerId) => {
    if (assignments?.length) {
      return assignments.filter(
        (assignment) => assignment?.property?.customer?.id === customerId
      )
    }
  }

  const getCustomerById = (customerId) => {
    if (customers?.length) {
      return customers.find(({ id }) => id === customerId)
    }
  }

  const getCustomerByInvoiceId = (invoiceId) => {
    if (customers?.length) {
      return customers.find(({ invoices }) =>
        invoices.some((invoice) => invoice.id === invoiceId)
      )
    }
  }

  const getInvoices = (params = {}) => {
    const { customerId } = params
    return customers
      .filter((customer) => !customerId || customer?.id === customerId)
      .flatMap(({ invoices }) => invoices)
      .sort((invoiceA, invoiceB) =>
        sort(invoiceA.invoiceNumber, invoiceB.invoiceNumber)
      )
  }

  const getInvoiceById = (invoiceId) => {
    return getInvoices().find(({ id }) => id === invoiceId)
  }

  const getVisitsYetToBeInvoiced = (params = {}) => {
    const { customerId } = params
    return customers
      .filter((customer) => !customerId || customer?.id === customerId)
      .flatMap(({ visitsYetToBeInvoiced, id }) =>
        visitsYetToBeInvoiced.map((visit) => ({ ...visit, customerId: id }))
      )
      .sort((visitA, visitB) =>
        sort(
          `${visitA.visitDate} ${visitA.startTime}`,
          `${visitB.visitDate} ${visitB.startTime}`
        )
      )
  }

  const getVisitById = (visitId) => {
    return getVisitsYetToBeInvoiced().find(({ id }) => id === visitId)
  }

  const getCurrentVisit = () => {
    return getVisitsYetToBeInvoiced().find(
      ({ user, tasks }) => !tasks?.length && user.email === currentUser.email
    )
  }

  const saveVisit = (customerId, assignmentId, visitToSave) => {
    const customer = getCustomerById(customerId)
    const visits = [...customer.visitsYetToBeInvoiced]
    const visitIndex = visits.findIndex(({ id }) => id === visitToSave.id)
    if (visitIndex === -1) {
      visits.push(visitToSave)
    } else {
      visits[visitIndex] = visitToSave
    }
    if (assignmentId) {
      const assignment = getAssignmentById(assignmentId)
      assignment.visit = visitToSave
      setAssignmentToSave(assignment)
    }
    setCustomerToSave({ ...customer, visitsYetToBeInvoiced: visits })
  }

  const saveInvoice = (customerId, invoiceToSave) => {
    const { invoices = [], ...customer } = customerId
      ? getCustomerById(customerId)
      : getCustomerByInvoiceId(invoiceToSave?.id)
    const visitIds = invoiceToSave.visits?.map(({ id }) => id)
    const invoiceIndex = invoices.findIndex(({ id }) => id === invoiceToSave.id)
    if (invoiceIndex > -1) {
      invoices[invoiceIndex] = invoiceToSave
    } else {
      invoices.push(invoiceToSave)
    }
    customer.visitsYetToBeInvoiced = customer.visitsYetToBeInvoiced.filter(
      ({ id }) => !visitIds.includes(id)
    )
    setCustomerToSave({ ...customer, invoices: [...invoices] })
  }

  useEffect(() => {
    if (customerToSave) {
      saveCustomer(customerToSave)
      setCustomerToSave(undefined)
    }
  }, [customerToSave])

  useEffect(() => {
    if (assignmentToSave) {
      saveAssignment(assignmentToSave)
      setAssignmentToSave(undefined)
    }
  }, [assignmentToSave])

  return (
    <DataContext.Provider
      value={{
        assignments,
        customers,
        taskTypes,
        reports,
        users,
        getAssignments,
        getAssignmentById,
        getAssignmentsByUserId,
        getAssignmentsByCustomerId,
        saveAssignment,
        deleteAssignment,
        getUserById,
        getCustomerById,
        getCustomerByInvoiceId,
        saveCustomer,
        getInvoices,
        getInvoiceById,
        saveInvoice,
        getVisitsYetToBeInvoiced,
        getVisitById,
        getCurrentVisit,
        saveVisit,
        deleteVisit,
        saveUser,
        loading,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
