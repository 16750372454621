import { Redirect, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import NaturePeopleIcon from '@mui/icons-material/NaturePeople'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PersonIcon from '@mui/icons-material/Person'
import { PageTitle } from '../../fragments/PageTitle'
import { MenuButton } from '../../fragments/Buttons/MenuButton'
import { CustomerDetails } from '../../fragments/CustomerDetails'
import { NavButtons } from '../../fragments/Buttons/NavButtons'
import { v4 as uuid } from 'uuid'
import AddIcon from '@mui/icons-material/Add'
import { useData } from '../../context/DataContext'
import VisitGroup from '../../fragments/VisitGroup'
import { useAuthentication } from '../../context/AuthenticationContext'

export default function Customer() {
  const {
    getCustomerById,
    getVisitsYetToBeInvoiced,
    getAssignmentsByCustomerId,
    getCurrentVisit,
    getInvoices,
  } = useData()
  const { isManager } = useAuthentication()
  const { customerId } = useParams()
  const [numberOfVisits, setNumberOfVisits] = useState(0)
  const [numberOfAssignments, setNumberOfAssignments] = useState(0)
  const [numberOfInvoices, setNumberOfInvoices] = useState(0)
  const [hasUnsentInvoices, setHasUnsentInvoices] = useState(false)
  const [hasIncompleteVisits, setHasIncompleteVisits] = useState(false)
  const [visitsForInvoice, setVisitsForInvoice] = useState([])
  const [activeVisit, setActiveVisit] = useState()

  const customer = getCustomerById && getCustomerById(customerId)
  const currentVisit = getCurrentVisit && getCurrentVisit(customerId)

  useEffect(() => {
    setVisitsForInvoice([])
    const visits = getVisitsYetToBeInvoiced({ customerId })
    setNumberOfVisits(visits.length)
    const activeVisit = visits.find((visit) => visit === currentVisit)
    if (activeVisit) {
      setActiveVisit(activeVisit)
    } else {
      const allActiveVisits = visits.filter(
        (visit) => !visit.datePaid && !visit.tasks?.length
      )
      if (!allActiveVisits?.length) {
        setVisitsForInvoice(visits)
      }
    }
    setHasIncompleteVisits(
      visits.some(({ datePaid, tasks }) => !datePaid && tasks?.length === 0)
    )
  }, [getVisitsYetToBeInvoiced, customerId])

  useEffect(() => {
    const invoices = getInvoices({ customerId })
    const unpaidInvoices = invoices.filter(({ datePaid }) => !datePaid)
    const numberOfInvoices = unpaidInvoices.length
    setNumberOfInvoices(numberOfInvoices)
    setHasUnsentInvoices(invoices.some(({ dateSent }) => !dateSent))
  }, [getInvoices, customerId])

  useEffect(() => {
    if (customerId) {
      const assignments = getAssignmentsByCustomerId(customerId)
      if (assignments?.length) {
        setNumberOfAssignments(
          assignments.filter((assignment) => !assignment.visit).length
        )
      }
    }
  }, [getAssignmentsByCustomerId, customerId])

  return (
    <>
      {!isManager ? (
        <Redirect to={{ pathname: '/Home' }} />
      ) : (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={PersonIcon} title="Customer" />
          <CustomerDetails edit={isManager} showBillPayer={isManager} />

          {customer && (
            <>
              {!!activeVisit && (
                <VisitGroup
                  customerId={customerId}
                  visits={[currentVisit]}
                  label="Current visit:"
                />
              )}

              <MenuButton
                to={`/Customers/${customerId}/Assignments/${uuid()}/Edit/Check`}
                icon={AddIcon}
                label="New Assignment"
              />

              <MenuButton
                to={`/Customers/${customerId}/Visits/${uuid()}/Edit?visitType=Items`}
                icon={AddIcon}
                label="Add Items"
              />

              {!!customerId && (
                <>
                  {!!visitsForInvoice?.some(
                    ({ tasks = [] }) => tasks.length
                  ) && (
                    <MenuButton
                      to={`/Customers/${customerId}/Invoices/${uuid()}/Edit`}
                      icon={ReceiptIcon}
                      label="Generate Invoice"
                    />
                  )}
                </>
              )}

              {!!numberOfAssignments && (
                <MenuButton
                  to={`/Customers/${customerId}/Assignments`}
                  icon={AccessTimeIcon}
                  label={`Assignments (${numberOfAssignments})`}
                />
              )}

              {!!numberOfVisits && (
                <MenuButton
                  to={`/Customers/${customerId}/Visits`}
                  icon={NaturePeopleIcon}
                  label={
                    `Visits (${numberOfVisits})` +
                    (hasIncompleteVisits ? ' ***' : '')
                  }
                />
              )}

              <MenuButton
                to={`/Customers/${customerId}/Invoices`}
                icon={ReceiptIcon}
                label={`Invoices ${
                  numberOfInvoices
                    ? `(${numberOfInvoices})` +
                      (hasUnsentInvoices ? ' ***' : '')
                    : ''
                }`}
              />

              {!customer?.billPayer && (
                <MenuButton
                  to={`/Customers/${customerId}/BillPayer/${uuid()}/Edit`}
                  icon={AddIcon}
                  label="Add Bill Payer"
                />
              )}
            </>
          )}

          <NavButtons backTo="/Customers" />
        </Grid>
      )}
    </>
  )
}
