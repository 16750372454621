export default function (address) {
  const {
    addressLine1 = '',
    addressLine2 = '',
    town = '',
    county = '',
    postcode = '',
  } = address
  return [addressLine1, addressLine2, town, county, postcode]
    .filter((part) => !!part?.trim())
    .join(', ')
}

export function directionsAddress(address) {
  const {
    addressLine1 = '',
    addressLine2 = '',
    town = '',
    county = '',
    postcode = '',
  } = address
  return [postcode, addressLine1, addressLine2, town, county]
    .filter((part) => !!part?.trim())
    .join(', ')
}
