import React, { useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login'
import { useAuthentication } from '../context/AuthenticationContext'
import { PageTitle } from '../fragments/PageTitle'
import Loader from '../fragments/Loader'
import { useParams } from 'react-router-dom'

export default function Login() {
  const { login, isAuthenticated, authenticating, hasToken, isDevelopment } =
    useAuthentication()

  const { slug } = useParams()

  function handleLogin() {
    if (slug) {
      login({ slug })
    } else {
      login()
    }
  }

  useEffect(() => {
    if (hasToken()) {
      login()
    }
  }, [hasToken])

  useEffect(() => {
    if (isAuthenticated()) {
      console.log('logged in')
    }
  }, [isAuthenticated])

  return (
    <>
      {!!authenticating ? (
        <Loader />
      ) : (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle title="Login" />
          <Grid item xs={12}>
            <Button
              startIcon={<LoginIcon />}
              variant="contained"
              color="primary"
              onClick={handleLogin}
            >
              {isDevelopment
                ? 'Login with test developer'
                : slug
                ? 'Login'
                : 'Login via Google'}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}
