import React, { useEffect, useState } from 'react'
import formatAddress from '../../utilities/formatAddress'

export function FullAddress({ address }) {
  const [addressLines, setAddressLines] = useState('')
  const [postcode, setPostcode] = useState('')

  useEffect(() => {
    if (address) {
      setAddressLines(formatAddress(address))
      setPostcode(address.postcode)
    }
  }, [address])

  return (
    <>
      {addressLines && (
        <>
          <br />
          <span> {addressLines.replace(', ' + postcode, '')}</span>
          <br />
          <span> {postcode} </span>
        </>
      )}
    </>
  )
}
