import {
  ListItemText,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material'
import AssignmentIcon from '@mui/icons-material/AccessTime'
import NaturePeopleIcon from '@mui/icons-material/NaturePeople'
import ReceiptIcon from '@mui/icons-material/Receipt'
import HomeIcon from '@mui/icons-material/Home'
import PeopleIcon from '@mui/icons-material/People'
import ReportIcon from '@mui/icons-material/Summarize'
import WorkerIcon from '@mui/icons-material/AccountBox'
import RefreshIcon from '@mui/icons-material/Refresh'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { Link } from 'react-router-dom'
import React from 'react'
import { useGlobal } from '../context/GlobalContext'
import { styled } from '@mui/material/styles'
import { useAuthentication } from '../context/AuthenticationContext'

const PREFIX = 'LdgApp-Menu'

const classes = {
  title: `${PREFIX}-title`,
}

const Root = styled('div')(({ theme }) => ({
  [`.${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
}))

export default function Menu() {
  const { menuVisible, setMenuVisible } = useGlobal()
  const { user, isManager, isAccounts, isDevelopment } = useAuthentication()

  const handleHideMenu = () => {
    if (menuVisible) {
      setMenuVisible(false)
    }
  }

  return (
    <Root>
      <Typography variant="h6" className={classes.title}>
        {user.firstName} {user.lastName}
      </Typography>
      <Typography variant="h6" className={classes.title}>
        {user.role.roleName}
      </Typography>
      <br />
      <MenuList variant="selectedMenu" onClick={handleHideMenu}>
        <MenuItem
          id="home-button"
          button="true"
          tabIndex={0}
          component={Link}
          to="/Home"
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Home</ListItemText>
        </MenuItem>
        {isManager && (
          <MenuItem
            id="customers-button"
            button="true"
            tabIndex={0}
            component={Link}
            to="/Customers"
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText>Customers</ListItemText>
          </MenuItem>
        )}
        {(isManager || !isAccounts) && (
          <>
            <MenuItem
              id="assignments-button"
              button="true"
              tabIndex={0}
              component={Link}
              to="/Assignments"
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText>Assignments</ListItemText>
            </MenuItem>
            <MenuItem
              id="visits-button"
              button="true"
              tabIndex={0}
              component={Link}
              to="/Visits"
            >
              <ListItemIcon>
                <NaturePeopleIcon />
              </ListItemIcon>
              <ListItemText>Visits</ListItemText>
            </MenuItem>
          </>
        )}
        {isManager && (
          <>
            <MenuItem
              id="invoices-button"
              button="true"
              tabIndex={0}
              component={Link}
              to="/Invoices"
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText>Invoices</ListItemText>
            </MenuItem>
            <MenuItem
              id="workers-button"
              button="true"
              tabIndex={0}
              component={Link}
              to="/Workers"
            >
              <ListItemIcon>
                <WorkerIcon />
              </ListItemIcon>
              <ListItemText>Workers</ListItemText>
            </MenuItem>
          </>
        )}
        {isAccounts && (
          <MenuItem
            id="reports-button"
            button="true"
            tabIndex={0}
            component={Link}
            to="/Reports"
          >
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText>Reports</ListItemText>
          </MenuItem>
        )}
        {(isManager || !isAccounts) && (
          <MenuItem
            id="profile-button"
            button="true"
            tabIndex={0}
            component={Link}
            to={`/Workers/${user.id}`}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          id="refresh-link"
          button="true"
          tabIndex={0}
          onClick={() => window.location.reload()}
        >
          <ListItemIcon>
            <RefreshIcon />
          </ListItemIcon>
          <ListItemText>Refresh</ListItemText>
        </MenuItem>
        {(user?.canLogout || isDevelopment) && (
          <MenuItem
            id="logout-link"
            button="true"
            tabIndex={0}
            component={Link}
            to="/Logout"
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        )}
      </MenuList>
    </Root>
  )
}
