import React, { useContext, useEffect, useState } from 'react'
import { googleLogout, useGoogleLogin } from '@react-oauth/google'
import useLocalStorageState from 'use-local-storage-state'

export const AuthenticationContext = React.createContext(undefined)

export const useAuthentication = () => useContext(AuthenticationContext)

const apiUrl = process.env.REACT_APP_API

export const AuthenticationProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [token, setToken] = useLocalStorageState('jkfd89sdfds', {
    ssr: true,
    defaultValue: null,
  })
  const [authenticating, setAuthenticating] = useState(false)
  const [isManager, setIsManager] = useState(false)
  const [isAccounts, setIsAccounts] = useState(false)
  const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development'

  const isAuthenticated = () => !!user?.id

  const hasToken = () => !!token

  useEffect(() => {
    setIsManager(['Admin', 'Manager'].includes(user?.role?.roleName))
    setIsAccounts(
      ['Admin', 'Manager', 'Accounts'].includes(user?.role?.roleName)
    )
  }, [user])

  const signOut = () => {
    setToken(null)
    googleLogout()
    fetch(`${apiUrl}/auth/logout`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then(() => console.log('logged out'))
  }

  const onLogoutSuccess = () => {
    setUser({})
    setToken(null)
  }

  const onError = () => {
    setAuthenticating(false)
    setUser({})
    setToken(null)
  }

  const onSuccess = async (res) => {
    setAuthenticating(true)
    const response = await fetch(`${apiUrl}/auth/login`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${res?.access_token}`,
        'Content-Type': 'application/json',
      },
    })

    setAuthenticating(false)

    if (response.ok) {
      const result = await response.json()
      const { user, token } = result

      if (user) {
        setUser(user)
        setToken(token)
      } else {
        signOut()
      }
    } else {
      signOut()
    }
  }

  const signInViaGoogle = useGoogleLogin({
    onSuccess,
    onError,
  })

  const login = (options) => {
    if (authenticating) {
      return null
    }
    setAuthenticating(true)
    if (token) {
      return onSuccess({ access_token: token })
    }
    if (options?.slug) {
      return onSuccess({ access_token: options?.slug })
    }
    if (isDevelopment) {
      return onSuccess({ access_token: 'test-token' })
    }
    return signInViaGoogle()
  }

  const logout = () => {
    if (!isDevelopment) {
      signOut()
    }
    return onLogoutSuccess()
  }

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        login,
        logout,
        token,
        authenticating,
        isAuthenticated,
        hasToken,
        isManager,
        isAccounts,
        isDevelopment,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
