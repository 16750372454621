import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'

import AssignmentIcon from '@mui/icons-material/AccessTime'
import NaturePeopleIcon from '@mui/icons-material/NaturePeople'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ReportIcon from '@mui/icons-material/Summarize'
import PeopleIcon from '@mui/icons-material/People'
import WorkerIcon from '@mui/icons-material/AccountBox'
import HomeIcon from '@mui/icons-material/Home'
import { PageTitle } from '../fragments/PageTitle'
import { MenuButton } from '../fragments/Buttons/MenuButton'
import { useData } from '../context/DataContext'
import VisitGroup from '../fragments/VisitGroup'
import Loader from '../fragments/Loader'
import { useAuthentication } from '../context/AuthenticationContext'
import MenuGroup from '../fragments/MenuGroup'

export default function Home() {
  const { getVisitsYetToBeInvoiced, loading, getInvoices, getAssignments } =
    useData()
  const { isManager, isAccounts, user } = useAuthentication()
  const [currentVisit, setCurrentVisit] = useState()
  const [numberOfUnprocessedVisits, setNumberOfUnprocessedVisits] = useState(0)
  const [numberOfAssignments, setNumberOfAssignments] = useState(0)
  const [hasUnsentInvoices, setHasUnsentInvoices] = useState(false)
  const [hasIncompleteVisits, setHasIncompleteVisits] = useState(false)
  const [invoices, setInvoices] = useState([])

  useEffect(() => {
    const visits = getVisitsYetToBeInvoiced()
    // The first open visit
    const currentVisit = visits.find(
      (visit) => visit?.tasks?.length === 0 && visit?.user?.email === user.email
    )
    if (currentVisit) {
      setCurrentVisit(currentVisit)
    }
    const unprocessedVisits = visits.filter((visit) => !visit.datePaid)
    setNumberOfUnprocessedVisits(unprocessedVisits.length)
    setHasIncompleteVisits(
      unprocessedVisits.some(
        ({ datePaid, tasks }) => !datePaid && tasks?.length === 0
      )
    )
  }, [getVisitsYetToBeInvoiced, user])

  useEffect(() => {
    const invoices = getInvoices().filter(({ datePaid }) => !datePaid)
    setInvoices(invoices)
    setHasUnsentInvoices(invoices.some(({ dateSent }) => !dateSent))
  }, [getInvoices])

  useEffect(() => {
    const assignments = getAssignments()
    setNumberOfAssignments(assignments.filter(({ visit }) => !visit).length)
  }, [getAssignments])

  return (
    <>
      {!loading ? (
        <Grid container direction="column" spacing={2} alignContent="stretch">
          <PageTitle icon={HomeIcon} title="Home" />

          {!!currentVisit && (
            <VisitGroup visits={[currentVisit]} label="Current visit:" />
          )}

          <MenuGroup label={' '}>
            {(isManager || !isAccounts) && (
              <MenuButton
                to="/Assignments"
                icon={AssignmentIcon}
                label={`Assignments ${
                  numberOfAssignments ? `(${numberOfAssignments})` : ''
                }`}
              />
            )}
            {isManager && (
              <MenuButton to="/Customers" icon={PeopleIcon} label="Customers" />
            )}
            {(isManager || !isAccounts) && (
              <MenuButton
                to="/Visits"
                icon={NaturePeopleIcon}
                label={`Visits ${
                  numberOfUnprocessedVisits
                    ? `(${numberOfUnprocessedVisits})` +
                      (hasIncompleteVisits ? ' ***' : '')
                    : ''
                }`}
              />
            )}
            {isManager && (
              <MenuButton
                to="/Invoices"
                icon={ReceiptIcon}
                label={`Invoices ${
                  invoices.length
                    ? `(${invoices.length})` + (hasUnsentInvoices ? ' ***' : '')
                    : ''
                }`}
              />
            )}
            {isAccounts && (
              <MenuButton to="/Reports" icon={ReportIcon} label="Reports" />
            )}
            {isManager && (
              <MenuButton to="/Workers" icon={WorkerIcon} label="Workers" />
            )}
          </MenuGroup>
        </Grid>
      ) : (
        <Loader />
      )}
    </>
  )
}
