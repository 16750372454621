import NaturePeopleIcon from '@mui/icons-material/NaturePeople'
import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useVisit } from '../../../context/VisitContext'
import { PageTitle } from '../../../fragments/PageTitle'
import { NavButtons } from '../../../fragments/Buttons/NavButtons'
import { useRouteMatch, useLocation, Redirect } from 'react-router-dom'
import { VisitCard } from '../../../fragments/VisitCard'
import { useData } from '../../../context/DataContext'

export default function VisitStart({ customerId, visitId }) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const assignmentId = searchParams.get('assignmentId')
  const { getAssignmentById } = useData()
  const { visitDateTime, setAssignmentId, setUser } = useVisit()
  const { visitDate } = visitDateTime || {}
  const [autoStart, setAutoStart] = useState(false)

  let match = useRouteMatch()

  const path = match.path
    .replace(':customerId', customerId)
    .replace(':visitId', visitId)

  const backTo = assignmentId
    ? '/Assignments'
    : path.substring(0, path.lastIndexOf('/Visits/'))

  const continueTo = path.substring(0, path.lastIndexOf('/Edit/') + 6) + 'Save'

  useEffect(() => {
    setAssignmentId(assignmentId)
    const assignment = getAssignmentById(assignmentId)
    setUser(assignment?.user)
    if (assignmentId) {
      setAutoStart(true)
    }
  }, [assignmentId, setAssignmentId])

  return (
    <>
      {!!autoStart ? (
        <Redirect to={{ pathname: continueTo }} />
      ) : (
        visitDate && (
          <Grid container direction="column" spacing={2} alignContent="stretch">
            <PageTitle icon={NaturePeopleIcon} title="Visit Start" />

            <Grid item xs={12}>
              <p>Please check this is correct before you press start:</p>
            </Grid>
            <VisitCard mode={'starting'} />
            <NavButtons
              backTo={backTo}
              backLabel="Cancel"
              continueTo={continueTo}
              continueLabel="Start"
            />
          </Grid>
        )
      )}
    </>
  )
}
