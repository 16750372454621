import React from 'react'
import { usePerson } from '../../../context/PersonContext'
import { distanceFields } from '../../../../constants/fields'
import PersonEditView from './PersonEditView'

export default function DistanceEdit(params) {
  const { distance, setDistance } = usePerson({})
  return (
    <>
      <PersonEditView
        {...params}
        type="number"
        prev="Address"
        fields={distanceFields}
        data={distance}
        setData={setDistance}
      />
    </>
  )
}
