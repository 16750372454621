import { Card, CardContent, Grid, IconButton } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import SmsIcon from '@mui/icons-material/Sms'
import EmailIcon from '@mui/icons-material/Email'
import KeyIcon from '@mui/icons-material/VpnKey'
import { Link } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useWorker } from '../context/WorkerContext'
import { useAuthentication } from '../context/AuthenticationContext'
import { FullAddress } from './FullAddress'
import formatCurrency from '../../utilities/formatCurrency'
import { StyledModal } from './StyledModal'

const PREFIX = 'LdgApp-Worker-Card'

const classes = {
  card: `${PREFIX}-card`,
  buttons: `${PREFIX}-buttons`,
}

const Root = styled('div')(({ theme }) => ({
  [`.${classes.card}`]: {
    backgroundColor: '#fccc7c',
    marginLeft: theme.spacing(2),
    position: 'relative',
  },
  [`.${classes.buttons}`]: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'space-around',
  },
}))

export function WorkerCard({ workerId }) {
  const { fullName, address, contact, taskTypeAndRate } = useWorker()
  const { user, isManager, token } = useAuthentication()
  const { phone, email } = contact
  const [resetWorkerModalOpen, setResetWorkerModalOpen] = useState(false)
  const sendUrl = `${process.env.REACT_APP_API}/api/users/reset-user`

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`
  }

  const handlePhoneClick = () => {
    window.location.href = `tel:${phone}`
  }

  const handleTextClick = () => {
    window.location.href = `sms:${phone}`
  }

  const handleResetWorkerClick = () => {
    setResetWorkerModalOpen(true)
  }

  const getRequestOptions = ({ body }) => {
    return {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  }

  const handleWorkerReset = () => {
    fetch(sendUrl, getRequestOptions({ body: { userId: workerId } }))
      .then((response) => {
        if (response.status !== 200) {
          console.warn(
            `Looks like there was a problem resetting the worker. Status Code: ${response.status}`
          )
        }
      })
      .catch((err) => {
        console.warn('Something went wrong while resetting the worker.', err)
      })
  }

  return (
    <Root>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <strong>{fullName}</strong>
            {!!address && <FullAddress address={address} />}
            <p>Rate: {formatCurrency(taskTypeAndRate?.rate)}</p>
          </CardContent>
          <div className={classes.buttons}>
            {user?.id !== workerId && (
              <>
                {!!phone && (
                  <>
                    <IconButton
                      edge="end"
                      variant="contained"
                      color={'primary'}
                      onClick={handlePhoneClick}
                      tabIndex={0}
                    >
                      <PhoneIcon />
                    </IconButton>
                    {phone.startsWith('07') && (
                      <IconButton
                        edge="end"
                        variant="contained"
                        color={'primary'}
                        onClick={handleTextClick}
                        tabIndex={0}
                      >
                        <SmsIcon />
                      </IconButton>
                    )}
                  </>
                )}
                {!!email && (
                  <>
                    <IconButton
                      edge="end"
                      variant="contained"
                      color={'primary'}
                      onClick={handleEmailClick}
                      tabIndex={0}
                    >
                      <EmailIcon />
                    </IconButton>
                    {!!isManager && (
                      <IconButton
                        edge="end"
                        variant="contained"
                        color={'primary'}
                        onClick={handleResetWorkerClick}
                        tabIndex={0}
                      >
                        <KeyIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </>
            )}
            <IconButton
              edge="end"
              variant="contained"
              color={'primary'}
              to={`/Workers/${workerId}/Edit`}
              component={Link}
              tabIndex={0}
            >
              <EditIcon />
            </IconButton>
          </div>
        </Card>
      </Grid>
      <StyledModal
        open={resetWorkerModalOpen}
        setOpen={setResetWorkerModalOpen}
        title="Are you sure you want to send a reset email to this worker?"
        onClickYes={handleWorkerReset}
      />
    </Root>
  )
}
